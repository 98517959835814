import React from 'react';
import Icon from '../Icon';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
}

const EditOption = ({ className }: Props) => (
  <Link to="/dashboard/my-profile">
    <Icon icon="edit" className={className} />
  </Link>
);

export default EditOption;
