import { useState, useEffect } from 'react';
import { MyQuizListRes, FinishedQuizListRes } from 'topdekarze-types';
import { useDispatch } from 'react-redux';
import ApiService from '../../services/api-service';
import { errorNotification } from '../../actions/notifications';

interface UseQuizList {
  quizzes: MyQuizListRes[];
  completedQuizzes: MyQuizListRes[];
  newQuizzes: MyQuizListRes[];
  isLoading: boolean;
  endedQuizzes: FinishedQuizListRes[];
}

const useQuizList = (): UseQuizList => {
  const [quizzes, setQuizzes] = useState<MyQuizListRes[]>([]);
  const [completedQuizzes, setCompletedQuizzes] = useState<MyQuizListRes[]>([]);
  const [newQuizzes, setNewQuizzes] = useState<MyQuizListRes[]>([]);
  const [endedQuizzes, setEndedQuizzes] = useState<FinishedQuizListRes[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getQuizzes = async () => {
    await ApiService.callFetch(
      'GET',
      'quiz/list',
      (data: MyQuizListRes[]) => {
        setQuizzes(data);
        setCompletedQuizzes(data.filter((quiz) => quiz.status === 'Zakończony'));
        setNewQuizzes(data.filter((quiz) => quiz.status !== 'Zakończony'));
        setIsLoading(false);
      },
      (error: any) => {
        setIsLoading(false);
        dispatch(errorNotification(error));
      },
    );
  };

  const getEndedQuizzes = async () => {
    await ApiService.callFetch(
      'GET',
      'quiz/finished-list',
      (data: FinishedQuizListRes[]) => {
        setEndedQuizzes(data);
        setIsLoading(false);
      },
      (error: any) => {
        setIsLoading(false);
        dispatch(errorNotification(error));
      },
    );
  };

  useEffect(() => {
    getQuizzes();
    getEndedQuizzes();
  }, []);

  return {
    quizzes,
    isLoading,
    completedQuizzes,
    newQuizzes,
    endedQuizzes,
  };
};

export default useQuizList;
