import React, { FC } from 'react';
import clsx from 'clsx';

import './Image.scss';

interface Props {
  image?: string;
  absolute?: boolean;
  className?: string;
  round?: boolean;
}

const Image: FC<Props> = ({ image, className, round, absolute }) => (
  <img
    className={clsx('image', {
      round,
      [`${className}`]: className,
    })}
    src={absolute ? image : `/assets/images/${image}`}
    alt=""
  />
);

export default Image;
