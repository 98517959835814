// import * as errorTranslations from '../data/errorsTranslations.json';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const errorTranslations = require('../data/errorsTranslations.json');
// JK: dont know why, but works. Sholud be import..
export function formOnTranslateString(s: string): string {
  const transes: {
    [lang: string]: {
      [key: string]: string;
    };
  } = errorTranslations;
  if (transes && transes.pl && transes.pl[s]) {
    return transes.pl[s];
  }
  return s;
}


const format = (s: string, params: any[]) => {
  return s.replace(/{(\d+)}/g, (match, number) => {
    return typeof params[number] != 'undefined' ? params[number] : match;
  });
};
const translateErrors = (data: any) => {
  if (data.length >= 1) {
    const returnData = {
      // @ts-ignore
      messageWithPlaceholders: formOnTranslateString(data[0].messageWithPlaceholders),
      // @ts-ignore
      messageCompiled: format(formOnTranslateString(data[0].messageWithPlaceholders), data[0].params),
      // @ts-ignore
      params: data[0].params,
    };
    return [returnData];
  } else {
    return data;
  }
};

export { format, translateErrors };