import React from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { Spinner, Input, Button } from '../../Common';
import ApiService from '../../../services/api-service';
import { Row, FormContainer } from '../../Layout';
import { successNotification } from '../../../actions/notifications';

import { __ } from '../../../helpers/i18n';
import { useDispatch } from 'react-redux';

const EmailLinkForm = () => {
  const dispatch = useDispatch();
  return (
    <FormContainer>
      <ItfApiForm
        formId="reset-pass-request-form"
        schemaMayBeDynamic={false}
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        schemaFetcher={() => ApiService.loadForm('user/form/reset-pass-request')}
        sendFetcher={(formId: string, values: any) => ApiService.sendForm('user/reset-pass-request', values, 'POST')}
        submitButton={
          <>
            <Row buttonsContainer>
              <Button primary type="submit" text={__('application.send')} />
            </Row>
          </>
        }
        onRenderFullField={(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur) => {
          switch (field.type) {
            case 'text':
              return (
                <Input
                  type={field.options.type}
                  placeholder={field.placeholder}
                  label={field.title}
                  input={{
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    errors,
                    value: currentValue,
                    onChange: (e: any) => onChange(e.target.value),
                    onFocus,
                    onBlur,
                  }}
                />
              );
            default:
              throw new Error('Unknown field type!');
          }
        }}
        onSavedSuccessfully={() => {
          dispatch(successNotification(__('application.messageSent')));
        }}
      />
    </FormContainer>
  );
};

export default EmailLinkForm;
