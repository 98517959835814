import React from 'react';
import { __ } from '../../../helpers/i18n';
import './UserActionBox.scss';
import Icon from '../Icon';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { UserRole } from 'topdekarze-types';

interface Props {
  bgIcon: string;
  path: string;
  backgroundColor: string;
  text: string;
}

const UserActionBox = ({ backgroundColor, bgIcon, text, path }: Props) => {
  const user = useSelector((state: RootState) => state.user.details);
  const styles = {
    backgroundColor: `${backgroundColor}`,
  };
  return (
    <Link
      className={clsx(`user-action-box`, user?.role === UserRole.SuperUser && 'superuser-action-box')}
      role="button"
      to={path}
    >
      <div style={styles} className="content-wrapper">
        <Icon icon={bgIcon} className="user-action-icon" />
        <p className="text">{__(text)}</p>
      </div>
    </Link>
  );
};

export default UserActionBox;
