import * as React from 'react';
import clsx from 'clsx';

import './Alert.scss';

interface AlertProps {
  type: string;
  text: string;
  simple?: boolean;
  notification?: boolean;
}

const Alert = ({ type, simple, notification, text }: AlertProps): JSX.Element => (
  <div className={clsx(`alert alert-${type}`, { 'alert-simple': simple, notification })}>{text}</div>
);

export default Alert;
