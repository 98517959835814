import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserRole } from 'topdekarze-types';
import { RootState } from '../../reducers';
import { Spinner } from '../Common';
import { rememberRedirect } from '../../utils/remeber-redirect';

interface Props {
  component: any;
  exact?: boolean;
  path?: string;
}

const RouteSuperUser = ({ exact, path, component }: Props): JSX.Element => {
  const role = useSelector((state: RootState) => state.user.details?.role);
  const loading = useSelector((state: RootState) => state.user.loading);

  if (loading) return <Spinner overlay />;

  if (role === UserRole.SuperUser || role === UserRole.Admin) {
    return <Route exact={exact} path={path} component={component} />;
  } else {
    rememberRedirect();
    return <Route render={() => <Redirect to="/" />} />;
  }
};

export default RouteSuperUser;
