import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { pointsHistorySchema } from 'topdekarze-tables';
import { PointsHistoryTableRes, UserRole } from 'topdekarze-types';
import ApiService from '../../../../services/api-service';
import { InteractiveTableSchema } from '../../../../utils/table-schema-utils';
import { Main, Container, ButtonsContainer } from '../../../../components/Layout';
import ApiTable from '../../../../components/Common/ApiTable';
import { DashboardBackground, TableWrapper, Button, Spinner, SectionTitle } from '../../../../components/Common';
import useHeader from '../../../../hooks/useHeader';
import EntrySection from '../../Start/EntrySection';

const UserHistory: FC = () => {
  useHeader('');
  const { userId } = useParams<{ userId: string }>();
  const [history, setHistory] = useState<any>();

  const getEvents = async () => {
    await ApiService.callFetch('GET', `ranking/history-for-user/${userId}`, (data: PointsHistoryTableRes[]) => {
      setHistory(data);
    });
  };

  useEffect(() => {
    getEvents();
  }, []);

  const scheme = new InteractiveTableSchema(pointsHistorySchema).removeFields('id');

  if (!history) return <Spinner />;

  return (
    <Main className="start-view">
      <DashboardBackground />
      <EntrySection noStats />
      <Container>
        <SectionTitle>Historia punktacji</SectionTitle>
      </Container>
      <TableWrapper>
        <ApiTable scheme={scheme} apiEndpointSubUrl={`ranking/history-for-user/${userId}`} noSearch />
      </TableWrapper>
    </Main>
  );
};

export default UserHistory;