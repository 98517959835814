import React, { useEffect, useState, FC } from 'react';
import { rankingTableSchema, FieldType, TableResponse } from 'topdekarze-tables';
import { RankingTableRes, EventTableRes, UserRole } from 'topdekarze-types';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { history } from '../../../../App';
import { InteractiveTableSchema } from '../../../../utils/table-schema-utils';
import { Main, Container, ButtonsContainer } from '../../../../components/Layout';
import { ApiTable, Button, Spinner, DashboardBackground, TableWrapper } from '../../../../components/Common';
import useHeader from '../../../../hooks/useHeader';
import EntrySection from '../../Start/EntrySection';
import ApiService from '../../../../services/api-service';
import { RootState } from '../../../../reducers';
import UserLevel from '../UserLevel';
import { refreshApiTable } from '../../../../actions/api-table';
import './MainRating.scss';

const MainRating: FC = () => {
  useHeader('');
  const dispatch = useDispatch();
  const [ratings, setRatings] = useState<{ id: string; title: string }[]>([]);
  const [rating, setRating] = useState<string>('');
  const user = useSelector((state: RootState) => state.user.details);

  const getEvents = async () => {
    await ApiService.callFetch('GET', 'event/list-ratings', (data: EventTableRes[]) => {
      let newRatings: { id: string; title: string }[] = [];
      if (user!.role === UserRole.User || user!.role === UserRole.Admin) {
        newRatings = [...newRatings, { id: 'ranking/main-ranking', title: 'Ranking główny' }];
      }
      if (user!.role === UserRole.SuperUser || user!.role === UserRole.Admin) {
        newRatings = [...newRatings, { id: 'ranking/superuser-ranking', title: 'Ranking DHT' }];
      }

      if (user!.role === UserRole.SuperUser) {
        newRatings = [...newRatings, { id: 'ranking/main-ranking', title: 'Ranking główny' }];
      }

      newRatings = [...newRatings, ...data.map((e) => ({ id: `ranking/special-ranking/${e.id}`, title: e.title }))];
      setRatings(newRatings);
      setRating(newRatings[0].id);
    });
  };

  useEffect(() => {
    getEvents();
  }, []);

  const updateRating = (id: string) => {
    setRating(id);
    dispatch(refreshApiTable());
  };

  const scheme = new InteractiveTableSchema(rankingTableSchema)
    .removeFields('id', 'firstName', 'lastName', 'points')
    .addFieldAtTheBeginning({
      field: 'participant',
      name: 'Uczestnik',
      type: FieldType.Custom,
      customRender: (value, field, row: RankingTableRes) => (
        <div className={clsx('rating-user')}>
          <img className="rating-avatar" src={`${ApiService.url}user/avatar/${row.id}`} alt="" />
          <strong className="rating-user-name">{`${row.firstName} ${row.lastName}`}</strong>
        </div>
      ),
    })
    .addFieldAtTheBeginning({
      field: 'place',
      name: 'Miejsce',
      type: FieldType.Custom,
      customRender: (value, field, row: RankingTableRes) => <div className={clsx('rating-place')}>{row.place}</div>,
    })
    .addFieldAtTheEnd({
      field: 'points',
      name: 'Punkty',
      type: FieldType.Custom,
      customRender: (value, field, row: RankingTableRes) => (
        <div className={clsx('rating-points')}>{`${row.points} pkt.`}</div>
      ),
    });

  if (user?.role === UserRole.Admin) {
    scheme.addFieldAtTheEnd({
      field: 'Historia punktów',
      name: 'Historia',
      type: FieldType.Custom,
      customRender: (value, field, row: RankingTableRes) => (
        <Button small click={() => history.push(`/dashboard/points-history/${row.id}`)} text="Historia punktów" />
      ),
    });
  }

  if (!rating) return <Spinner />;

  return (
    <Main className="start-view">
      <DashboardBackground />
      <EntrySection noStats />
      {user?.role === UserRole.User && <UserLevel />}
      <Container>
        <ButtonsContainer className="available-ratings">
          {ratings.map((r) => (
            <Button primary disabled={r.id === rating} text={r.title} click={() => updateRating(r.id)} />
          ))}
        </ButtonsContainer>
      </Container>
      <TableWrapper>
        <ApiTable
          scheme={scheme}
          apiEndpointSubUrl={rating}
          noSearch
          highlight={(row: RankingTableRes) => row.id === user!.id}
        />
      </TableWrapper>
    </Main>
  );
};

export default MainRating;
