import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EventRes } from 'topdekarze-types';
import ApiService from '../../services/api-service';
import { errorNotification } from '../../actions/notifications';

interface UseEventList {
  events: EventRes[];
  isLoading: boolean;
}

const useEventList = (): UseEventList => {
  const [events, setEvents] = useState<EventRes[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const getEvents = async () => {
    await ApiService.callFetch(
      'GET',
      'event/list',
      (data: any) => {
        setEvents(data);
        setIsLoading(false);
      },
      (error: any) => {
        setIsLoading(false);
        dispatch(errorNotification(error));
      },
    );
  };

  useEffect(() => {
    getEvents();
  }, []);

  return {
    events,
    isLoading,
  };
};

export default useEventList;
