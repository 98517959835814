import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Column } from '../../Layout';
import InfoBox from '../../../components/Common/InfoBox';
import ApiService from '../../../services/api-service';
import './Card.scss';

interface Props {
  title: string;
  isFirst: boolean;
  to?: string;
  li?: boolean;
  imgUrl?: string;
  image?: string;
  isTitleOnPhoto?: boolean;
  extraText?: string;
  className?: string;
  completed?: boolean;
  points?: string;
  center?: boolean;
  time?: string;
  specialTask?: boolean;
}

const Card = ({
  title,
  extraText,
  isTitleOnPhoto,
  isFirst,
  imgUrl,
  image,
  className,
  to,
  li,
  completed,
  points,
  center,
  time,
  specialTask,
}: Props): JSX.Element => (
  <Column
    className={clsx('card', { [`${className}`]: className, completed })}
    sm={isFirst ? 12 : 4}
    as={li ? 'li' : 'div'}
    center={!!center}
  >
    <div className="card-wrapper">
      <Link className="content" to={to || '#'}>
        {imgUrl && <img src={`${ApiService.url}${imgUrl}`} alt="" />}
        {image && <img src={`/assets/images/${image}`} alt="" />}
        {extraText && <p className="extra-text">{extraText}</p>}
        {time && (
          <div className="deadline">
            <InfoBox className="active onImage" text={time} />
          </div>
        )}
        <div className={clsx('quiz-shortcut', { 'special-task': specialTask })}>
          <p className={clsx('title', { overlay: isTitleOnPhoto, first: isFirst })}>{title}</p>
          {points && <InfoBox className={completed ? 'main' : 'active'} text={points} />}
        </div>
      </Link>
    </div>
  </Column>
);

export default Card;
