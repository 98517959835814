import React from 'react';
import clsx from 'clsx';
import './Row.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  center?: boolean;
  jBetween?: boolean;
  buttonsContainer?: boolean;
  as?: 'div' | 'ul' | 'section';
  onClick?: () => void;
}

const Row = ({ children, className, center, buttonsContainer, as: Tag = 'div', jBetween, onClick }: Props) => (
  <Tag
    onClick={onClick}
    className={clsx('row', {
      center,
      ' buttons-container': buttonsContainer,
      [`${className}`]: className,
      'justify-between': jBetween,
    })}
  >
    {children}
  </Tag>
);

export default Row;
