import React from 'react';
import { Switch, Route } from 'react-router-dom';
import EmailLink from './EmailLink';
import NewPassword from './NewPassword';
import { Main } from '../../components/Layout';
import useHeader from '../../hooks/useHeader';
import { __ } from '../../helpers/i18n';

const ResetPassword = () => {
  useHeader(`${__('application.giveEmail')}`, '/', true);
  return (
    <Main noLeft>
      <Switch>
        <Route exact path="/restore-pass" component={EmailLink} />
        <Route path="/restore-pass/:userId/:updateToken" component={NewPassword} />
      </Switch>
    </Main>
  );
};

export default ResetPassword;
