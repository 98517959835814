import React, { FC } from 'react';
import { Container, Main, Row } from '../../../../components/Layout';
import AddArticlesForm from '../../../../components/Form/AddArticlesForm';

const AddArticle: FC = () => (
  <Main>
    <Container>
      <Row>
        <AddArticlesForm />
      </Row>
    </Container>
  </Main>
);

export default AddArticle;
