import React, { ReactNode } from 'react';

import './EntryText.scss';

interface Props {
  children: ReactNode;
}

const EntryText = ({ children }: Props) => <p className="entry-text">{children}</p>;

export default EntryText;
