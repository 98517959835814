import React from 'react';
import { useDispatch } from 'react-redux';
import Icon from '../Icon';
import { logout } from '../../../actions/auth';
import { history } from '../../../App';
import { errorNotification } from '../../../actions/notifications';

const Logout = () => {
  const dispatch = useDispatch();
  const handleLogout = async () => {
    try {
      await dispatch(logout());
      history.push('/');
    } catch (e) {
      dispatch(errorNotification(e));
    }
  };
  return (
    <div role="button" onClick={handleLogout}>
      <Icon className="nav-icon" icon="logout" />
    </div>
  );
};

export default Logout;
