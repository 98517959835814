import React from 'react';
import ExternalLink from '../../ExternalLink';
import './SocialItem.scss';

interface Props {
  iconName: string;
  path: string;
}

const SocialItem = ({ iconName, path }: Props) => (
  <li key={iconName}>
    <ExternalLink blank path={path} icon={iconName} />
  </li>
);

export default SocialItem;
