import React from 'react';
import Icon from '../Icon';
import { history } from '../../../App';

import './BackArrow.scss';

interface Props {
  arrowName?: string;
  path?: string;
}

const BackArrow = ({ arrowName, path }: Props) => {
  const handleBack = () => {
    if (path) history.push(path);
    else history.go(-1);
  };
  return <Icon className="back-arrow" onClickHandler={handleBack} icon={arrowName || 'back-arrow'} />;
};

export default BackArrow;
