import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FormFieldType } from 'itf_formbuilder';
import { ItfApiForm } from 'itf_formbuilder_react';
import { InviteUserReq } from 'topdekarze-types';
import FormContainer from '../../Layout/FormContainer';
import { Spinner, Input, Button } from '../../Common';
import ApiService from '../../../services/api-service';
import { successNotification } from '../../../actions/notifications';
import { __ } from '../../../helpers/i18n';
import { ButtonsContainer } from '../../Layout';

const InviteUserForm = (): JSX.Element => {
  const ref = useRef<ItfApiForm>(null);

  const dispatch = useDispatch();

  return (
    <FormContainer>
      <ItfApiForm
        ref={ref}
        formId="inviteUserForm"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        schemaFetcher={() => ApiService.loadForm('user/form/invite-user')}
        sendFetcher={(formId: string, values: InviteUserReq) => ApiService.sendForm('user/invite-user', values)}
        submitButton={(
          <ButtonsContainer>
            <Button primary type="submit" text={__('application.send')} />
          </ButtonsContainer>
        )}
        onRenderFullField={(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur) => {
          switch (field.type) {
            case FormFieldType.Text:
              return (
                <Input
                  type={field.options.type || field.type}
                  placeholder={field.placeholder}
                  label={field.title}
                  input={{
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    errors,
                    value: currentValue,
                    onChange: (e: any) => onChange(e.target.value),
                    onFocus,
                    onBlur,
                  }}
                />
              );
            default:
              return null;
          }
        }}
        onSavedSuccessfully={() => {
          dispatch(successNotification(`${__('application.invitationSent')}`));
          if (ref.current) ref.current.api.resetValues();
        }}
      />
    </FormContainer>
  );
};

export default InviteUserForm;
