import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { Spinner } from '../Common';
import { rememberRedirect } from '../../utils/remeber-redirect';

interface Props {
  component: any;
  exact?: boolean;
  path?: string;
}

const RouteAuthorized = ({ exact, path, component }: Props): JSX.Element => {
  const user = useSelector((state: RootState) => state.user.details);
  const loading = useSelector((state: RootState) => state.user.loading);
  if (loading) return <Spinner overlay />;

  if (user) {
    if (!user.rulesAccepted) return <Route render={() => <Redirect to="/rules" />} />;
    return <Route exact={exact} path={path} component={component} />;
  } else {
    rememberRedirect();
    return <Route render={() => <Redirect to="/" />} />;
  }
};

export default RouteAuthorized;
