import React from 'react';
import useHeader from '../../hooks/useHeader';
import LoginForm from '../../components/Form/LoginForm';
import { Main, FormContainer } from '../../components/Layout';
import DownContent from '../../components/Common/DownContent';
import WelcomeText from '../../components/Common/WelcomeText';
import { __ } from '../../helpers/i18n';

import './Login.scss';

const Login = () => {
  useHeader('Zaloguj się', '/', true);
  return (
    <Main noLeft alignEnd>
      <FormContainer>
        <DownContent
          form={<LoginForm />}
          header={
            <WelcomeText className="login-welcome">
              {__('application.welcome')},<small>{__('application.loginWelcome')}</small>
            </WelcomeText>
          }
        />
      </FormContainer>
    </Main>
  );
};

export default Login;
