import React from 'react';
import { ReactSVG } from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import './Icon.scss';

interface Props {
  className?: string;
  icon?: string;
  type?: string;
  faIcon?: IconProp;
  big?: boolean;
  onClickHandler?: () => void;
}

const Icon = ({ icon, faIcon, className, big, onClickHandler }: Props) => (
  <div className={`icon-wrapper ${className || ''} ${big ? 'big' : ''}`}>
    <div role={onClickHandler ? 'button' : undefined} onClick={onClickHandler} className="icon">
      {faIcon && <FontAwesomeIcon icon={faIcon} />}
      {icon && <ReactSVG src={`/assets/images/${icon}.svg`} />}
    </div>
  </div>
);

export default Icon;
