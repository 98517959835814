import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Column, Row } from '../../Layout';
import { RootState } from '../../../reducers';
import UserImage from '../UserImage';

import './UserDetails.scss';

interface Props {
  showAvatar?: boolean;
  className?: string;
  hideEmail?: boolean;
}

const UserDetails: FC<Props> = ({ showAvatar, className, hideEmail }) => {
  const user = useSelector((state: RootState) => state.user.details);

  if (user) {
    const { firstName, lastName, email } = user;
    const fullName = () => `${firstName} ${lastName}`;
    return (
      <Row center className={clsx('user-details', { [`${className}`]: className })}>
        {showAvatar && (
          <Column className="top-col">
            <UserImage />
          </Column>
        )}
        <Column className="top-col">
          <span className="name">{fullName()}</span>
        </Column>
        {!hideEmail && (
          <Column className="top-col">
            <span className="contact-data">{email}</span>
          </Column>
        )}
      </Row>
    );
  }
  return null;
};

export default UserDetails;
