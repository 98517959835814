import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { getRememberedRedirect, clearRememberedRedirect } from '../../utils/remeber-redirect';

interface Props {
  component: any;
  exact?: boolean;
  path?: string;
}

const RouteUnauthorized = ({ exact, path, component }: Props) => {
  const user = useSelector((state: RootState) => state.user.details);

  if (!user) {
    return <Route exact={exact} path={path} component={component} />;
  } else {
    const redirect = getRememberedRedirect();

    if (redirect && redirect.to) {
      clearRememberedRedirect();
      return <Route render={() => <Redirect to={redirect.to} />} />;
    }
    return <Route render={() => <Redirect to="/dashboard" />} />;
  }
};

export default RouteUnauthorized;
