import React, { FC } from 'react';
import clsx from 'clsx';

import './ButtonsContainer.scss';

interface Props {
  children: React.ReactNode;
  max?: boolean;
  className?: string;
  center?: boolean;
  marginB?: boolean;
}

const ButtonsContainer: FC<Props> = ({ children, max, center, className, marginB }) => (
  <div className={clsx('buttons-container', className, { max, center, 'margin-bottom': marginB })}>{children}</div>
);

export default ButtonsContainer;
