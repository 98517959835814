import sanitizeHtml from 'sanitize-html';

export const sanitize = (input: any) => {
  const sanitized = sanitizeHtml(input, {
    allowedTags: [
      'b',
      'i',
      'u',
      'br',
      'span',
      'strong',
      'a',
      'p',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'img',
      'figure',
      'ol',
      'ul',
      'li',
      'iframe',
    ],
    allowedAttributes: {
      a: ['href', 'target', 'class'],
      img: ['src'],
    },
  });

  return sanitized;
};
