import React from 'react';
import { Mode } from '../../../hooks/articles/useArticlePreview';
import { Column } from '../../Layout';
import { Button } from '..';

import './ChangeMode.scss';

interface Props {
  mode: Mode;
  changeMode: (mode: Mode) => void;
}

const ChangeMode = ({ mode, changeMode }: Props): JSX.Element => (
  <Column className="change-mode">
    <Button className="change-mode-button" primary text="Zmień tryb" click={() => changeMode(mode)} />
    <p>Aktualny tryb: {mode}</p>
  </Column>
);

export default ChangeMode;
