import React, { ReactNode } from 'react';
import { Row, Container, Column } from '../../Layout';

import './DownContent.scss';

interface Props {
  form: ReactNode;
  header: ReactNode;
}

const DownContent = ({ form, header }: Props) => (
  <Container className="content-bottom">
    <Row>
      <Column>
        {header}
        {form}
      </Column>
    </Row>
  </Container>
);

export default DownContent;
