import React, { FC } from 'react';
import { Main, Container } from '../../../../components/Layout';
import useHeader from '../../../../hooks/useHeader';
import EditSpecialActionForm from '../../../../components/Form/EditSpecialActioForm';

const EditSpecialAction: FC = () => {
  useHeader('Edytuj zadanie specjalne', '/dashboard/special-tasks');

  return (
    <Main>
      <Container>
        <EditSpecialActionForm />
      </Container>
    </Main>
  );
};

export default EditSpecialAction;
