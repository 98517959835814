import React from 'react';
import RegisterInvitedForm from '../../components/Form/RegisterInvitedForm';
import useHeader from '../../hooks/useHeader';
import WelcomeText from '../../components/Common/WelcomeText';
import { Main } from '../../components/Layout';
import DownContent from '../../components/Common/DownContent';

import { __ } from '../../helpers/i18n';
import '../Register/Register.scss';

const WelcomeView = () => {
  useHeader('Zarejestruj się', '/', true);

  return (
    <Main className="main-register" noLeft>
      <DownContent
        form={<RegisterInvitedForm />}
        header={
          <WelcomeText className="register-welcome">
            {__('application.welcome')},<small>{__('application.registerWelcome')},</small>
          </WelcomeText>
        }
      />
    </Main>
  );
};

export default WelcomeView;
