import { getDefaultLanguage } from '../utils/language';

export default class SessionService {
  static setCurrentLanguage(lang: string) {
    localStorage.setItem('language', JSON.stringify(lang));
  }

  static getCurrentLanguage() {
    try {
      return JSON.parse(localStorage.getItem('language') || 'null') || getDefaultLanguage();
    } catch {
      return getDefaultLanguage();
    }
  }

  // @TODO: fix user type
  static setUser(user: any) {
    return localStorage.setItem('user', JSON.stringify(user));
  }

  static clearSessionStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  static clearUserSession() {
    localStorage.removeItem('user');
  }

  static isUserSet() {
    return JSON.parse(localStorage.getItem('user') || 'false');
  }

  static getUser() {
    return JSON.parse(localStorage.getItem('user') || 'null');
  }
}
