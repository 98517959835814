import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setHeader as setHeaderAction } from '../actions/header';

const useHeader = (title: string, back?: string, noLeft?: boolean, hideOnMobile?: boolean): void => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHeaderAction(title, back, noLeft, hideOnMobile));
  }, []);
};
export default useHeader;
