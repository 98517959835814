// @ts-nocheck
// TODO Types to fix, form-field as one component, eslint warnings
import React, { FC } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import { FormField, QuestionArrayForm } from '../../../../../components/Common';
import useHeader from '../../../../../hooks/useHeader';
import { useCreateQuizForm } from '../../../../../hooks/quizzes/useCreateQuizForm';

import './CreateQuizForm.scss';

const CreateQuizForm: FC = () => {
  useHeader('Nowy quiz', '/dashboard/');
  const { register, handleSubmit, control, errors } = useForm({
    mode: 'onBlur',
  });

  const { mainPhoto, submit, handleImagePreview } = useCreateQuizForm();

  return (
    <FormProvider {...{ register, handleSubmit, control, errors }}>
      <form className="create-quiz-form" onSubmit={handleSubmit(submit)} encType="multipart/form-data">
        <FormField labelText="Tytuł*" type="text" name="title" placeholderText="Quiz o dachówkach" isRequired />
        <FormField
          labelText="Obrazek wyróżniający*"
          labelClassname="quiz-photo"
          type="file"
          name="quizPictureFn"
          isRequired
          handler={handleImagePreview}
          accept="image/*"
        >
          <div className="photo-wrapper">{mainPhoto?.previewUrl && <img src={mainPhoto?.previewUrl} alt="quiz" />}</div>
        </FormField>
        <FormField
          labelText="Liczba punktów do zdobycia za jedno pytanie*"
          type="number"
          name="pointsPerQuestion"
          placeholderText="Punkty za pytanie"
          isRequired
        />
        <FormField
          labelText="Wybierz zakres dat, w których quiz będzie dostępny*"
          type=""
          name=""
          placeholderTxt="Punkty za pytanie"
          isRequired
          field={
            <Controller
              as={<Flatpickr options={{ allowInput: false, mode: 'range', minDate: 'today' }} />}
              control={control}
              name="date"
              isRequired
            />
          }
        />
        <QuestionArrayForm />
      </form>
    </FormProvider>
  );
};

export default CreateQuizForm;
