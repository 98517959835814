export function formatThousands(n: number, isHtml = false, toFixed = 2): string {
  return Number(n)
    .toFixed(toFixed)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${isHtml ? '&nbsp;' : ' '}`)
    .replace('.', ',');
}
export function formatPrice(n: number, isHtml = false): string {
  return `${formatThousands(n, isHtml)}zł`;
}
export const parsePrice = (value: string) => {
  // eslint-disable-next-line no-param-reassign
  value = value.replace(/[^0-9.]/g, '');
  const sections = value.split('.');
  if (sections[0] !== '0' && sections[0] !== '00') {
    sections[0] = sections[0].replace(/^0+/, '');
  } else {
    sections[0] = '0';
  }
  if (sections[1]) {
    return `${sections[0]}.${sections[1].slice(0, 2)}`;
  } else if (value.indexOf('.') !== -1) {
    return `${sections[0]}.`;
  } else {
    return sections[0];
  }
};
