import React from 'react';
import clsx from 'clsx';
import Icon from '../Icon';
import { history } from '../../../App';

import './Brand.scss';

interface Props {
  className?: string;
  auto?: boolean;
}

const Brand = ({ className, auto }: Props): JSX.Element => (
  <Icon
    icon="logotyp"
    className={clsx('brand', { [`${className}`]: className })}
    onClickHandler={() => history.push('/dashboard')}
  />
);

export default Brand;
