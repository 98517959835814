import React from 'react';
import './ModalTitle.scss';

interface Props {
  title: string;
}

const ModalTitle = ({ title }: Props) => <h2 className="modal-title">{title}</h2>;

export default ModalTitle;
