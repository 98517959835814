import React from 'react';
import { Main, ButtonsContainer, Container } from '../../components/Layout';
import PageTitle from '../../components/Common/PageTitle';
import { Button } from '../../components/Common';

import './Page404.scss';

const Page404 = () => (
  <Main noLeft className="page-not-found-wrapper">
    <Container>
      <div className="page-not-found">
        <PageTitle>Przepraszamy, nie znaleziono żądanej strony.</PageTitle>
        <ButtonsContainer>
          <Button big primary to="/" text="Strona główna" />
        </ButtonsContainer>
      </div>
    </Container>
  </Main>
);

export default Page404;
