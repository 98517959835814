import { RealizationImages } from '../views/Dashboard/Transactions/AddTransaction';
import { HeaderState } from './header-reducer';
import { setImages } from '../actions/mutliple-photo';

interface SetImage {
  type: 'SET_IMAGE';
  payload: RealizationImages;
}

interface RemovePreviewImage {
  type: 'REMOVE_PREVIEW_IMAGE';
  payload: string;
}

interface ClearPreviewImage {
  type: 'CLEAR_PREVIEW_IMAGE';
}

type Action = SetImage | RemovePreviewImage | ClearPreviewImage;

const initialState = [] as RealizationImages[];

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_IMAGE':
      return [...state, action.payload];
    case 'REMOVE_PREVIEW_IMAGE':
      const { payload } = action;
      URL.revokeObjectURL(payload);
      const removeImage = (image: RealizationImages) => image.previewUrl !== payload;
      return state.filter(removeImage);
    case 'CLEAR_PREVIEW_IMAGE':
      state.forEach((image) => {
        URL.revokeObjectURL(image.previewUrl);
      });
      return [];
    default:
      return state;
  }
};
