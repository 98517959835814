import React from 'react';
import Icon from '../Icon';

interface Props {
  path: string;
  icon?: string;
  name?: string;
  blank?: boolean;
}

const ExternalLink = ({ path, icon, name, blank }: Props) => (
  <a target={blank ? '__blank' : undefined} href={path}>
    {icon ? <Icon big className="external-link-icon" icon={icon} /> : null}
    {name ? <p>name</p> : null}
  </a>
);

export default ExternalLink;
