import React from 'react';
import PageTopHeader from '../../../components/Common/PageTopHeader';
import NewPasswordForm from '../../../components/Form/NewPasswordForm';
import DownContent from '../../../components/Common/DownContent';
import WelcomeText from '../../../components/Common/WelcomeText';
import { __ } from '../../../helpers/i18n';

const NewPassword = () => (
  <>
    <PageTopHeader text={__('application.newPassHeader')} backPath="/" />
    <DownContent
      form={<NewPasswordForm />}
      header={(
        <WelcomeText>
          {__('application.entry')}
          <small>{__('application.newPassword')}</small>
        </WelcomeText>
      )}
    />
  </>
);

export default NewPassword;
