import React, { ReactNode } from 'react';

import './SectionSubtitle.scss';

interface Props {
  children: ReactNode;
}

const SectionSubtitle = ({ children }: Props) => <h2 className="section-subtitle">{children}</h2>;

export default SectionSubtitle;
