import React, { useRef, FC } from 'react';
import { useDispatch } from 'react-redux';
import { FormFieldType } from 'itf_formbuilder';
import { ItfApiForm } from 'itf_formbuilder_react';
import { SuperuserReq } from 'topdekarze-types';
import { Spinner, Input, Button } from '../../Common';
import { ButtonsContainer, FormContainer } from '../../Layout';
import ApiService from '../../../services/api-service';
import { successNotification } from '../../../actions/notifications';
import { __ } from '../../../helpers/i18n';
import { formOnTranslateString, translateErrors } from '../../../utils/trans-form';

import './AddUserForm.scss';

interface Props {
  className?: string;
}

const AddUserForm: FC<Props> = ({ className }) => {
  const ref = useRef<ItfApiForm>(null);

  const dispatch = useDispatch();

  return (
    <FormContainer grid>
      <ItfApiForm
        ref={ref}
        formId="addUserForm"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        schemaFetcher={() => ApiService.loadForm('admin/form/add')}
        onTranslateString={formOnTranslateString}
        sendFetcher={(formId: string, values: SuperuserReq) => ApiService.sendForm('admin/add', values)}
        submitButton={
          <ButtonsContainer>
            <Button secondary type="submit" text={__('application.add')} />
          </ButtonsContainer>
        }
        onRenderFullField={(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur) => {
          switch (field.type) {
            case FormFieldType.Text:
              return (
                <Input
                  type={field.options.type || field.type}
                  placeholder={field.placeholder}
                  label={field.title}
                  input={{
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    errors: translateErrors(errors),
                    value: currentValue,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
                    onFocus,
                    onBlur,
                  }}
                />
              );
            default:
              return null;
          }
        }}
        onSavedSuccessfully={() => {
          dispatch(successNotification(__('application.userAdded')));
          if (ref.current) ref.current.api.resetValues();
        }}
      />
    </FormContainer>
  );
};

export default AddUserForm;
