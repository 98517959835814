/* eslint-disable import/prefer-default-export */
import React, { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { QuestionStatus } from 'topdekarze-types';
import { SectionTitle, Button } from '..';
import { useCreateQuizForm } from '../../../hooks/quizzes/useCreateQuizForm';
import { ButtonsContainer } from '../../Layout';

export const QuestionArrayForm: FC = () => {
  const { control, register, errors } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  });

  const { questionImages, handleQuestionImagePreview } = useCreateQuizForm();
  return (
    <>
      <ul className="question-quiz">
        {fields.map((item: any, index: number) => (
          <React.Fragment key={item.id}>
            <li className="form-field">
              <div className="question-header">
                <SectionTitle>Pytanie {index + 1}</SectionTitle>
                <Button small round click={() => remove(index)} text="-" />
              </div>
              <input
                name={`questions[${index}].question`}
                ref={register({ required: 'To pole nie może być puste' })}
                defaultValue={item.question}
                placeholder="Pytanie*"
              />
              {errors?.questions?.length && (
                <p className="formfield-input-error">{errors?.questions[index]?.question?.message}</p>
              )}
              {errors?.questions?.length && errors?.questions[index]?.answer?.isCorrect && (
                <p className="formfield-input-error">{errors?.questions[index]?.answer?.isCorrect.message}</p>
              )}
              <div className="form-field">
                <label htmlFor={`questions[${index}].questionPictureFn`} className="quiz-photo">
                  Obrazek wyróżniający
                  <img src={questionImages[index]?.previewUrl} alt="" />
                  <input
                    onChange={(e) => handleQuestionImagePreview(e)}
                    type="file"
                    ref={register()}
                    name={`questions[${index}].questionPictureFn`}
                    accept="image/*"
                  />
                </label>
              </div>
              <ul className="answears">
                <div className="question-header">
                  <h4>Odpowiedzi</h4>
                  <h4>Poprawna</h4>
                </div>
                <li className="form-field">
                  <input
                    ref={register({ required: 'To pole nie może być puste' })}
                    type="text"
                    name={`questions[${index}].answer[0].content`}
                    placeholder="Odpowiedź nr 1*"
                  />
                  <input
                    ref={register({ required: 'Zaznacz poprawną odpowiedź' })}
                    type="radio"
                    name={`questions[${index}].answer.isCorrect`}
                    value="0"
                  />
                </li>
                {errors?.questions?.length && (
                  <p className="formfield-input-error">{errors?.questions[index]?.answer?.[0]?.content?.message}</p>
                )}
                <li className="form-field">
                  <input
                    ref={register({ required: 'To pole nie może być puste' })}
                    type="text"
                    name={`questions[${index}].answer[1].content`}
                    placeholder="Odpowiedź nr 2*"
                  />
                  <input
                    ref={register({ required: 'Zaznacz poprawną odpowiedź' })}
                    type="radio"
                    name={`questions[${index}].answer.isCorrect`}
                    value="1"
                  />
                </li>
                {errors?.questions?.length && (
                  <p className="formfield-input-error">{errors?.questions[index]?.answer?.[1]?.content?.message}</p>
                )}
                <li className="form-field">
                  <input
                    ref={register({ required: 'To pole nie może być puste' })}
                    type="text"
                    name={`questions[${index}].answer[2].content`}
                    placeholder="Odpowiedź nr 3*"
                  />
                  <input
                    ref={register({ required: 'Zaznacz poprawną odpowiedź' })}
                    type="radio"
                    name={`questions[${index}].answer.isCorrect`}
                    value="2"
                  />
                </li>
                {errors?.questions?.length && (
                  <p className="formfield-input-error">{errors?.questions[index]?.answer?.[2]?.content?.message}</p>
                )}
                <li className="form-field">
                  <input
                    ref={register({ required: 'To pole nie może być puste' })}
                    type="text"
                    name={`questions[${index}].answer[3].content`}
                    placeholder="Odpowiedź nr 4*"
                  />
                  <input
                    ref={register({ required: 'Zaznacz poprawną odpowiedź' })}
                    type="radio"
                    name={`questions[${index}].answer.isCorrect`}
                    value="3"
                  />
                </li>
                {errors?.questions?.length && (
                  <p className="formfield-input-error">{errors?.questions[index]?.answer?.[3]?.content?.message}</p>
                )}
              </ul>
            </li>
          </React.Fragment>
        ))}
      </ul>
      <div className="actions">
        <ButtonsContainer center>
          <Button small click={() => append({})} text="Dodaj pytanie" />
        </ButtonsContainer>
        {fields.length > 0 && (
          <ButtonsContainer>
            <Button primary type="submit" text="Dodaj quiz" />
          </ButtonsContainer>
        )}
      </div>
    </>
  );
};
