import React, { ReactNode, FC } from 'react';

import './SectionTitle.scss';
import clsx from 'clsx';

interface Props {
  children: ReactNode;
  highlight?: boolean;
}

const SectionTitle: FC<Props> = ({ children, highlight }) => (
  <h2 className={clsx('section-title', { highlight })}>{children}</h2>
);

export default SectionTitle;
