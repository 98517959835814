import React, { FC, useState } from 'react';
import { UserTableRes, UserWithLogoTableRes } from 'topdekarze-types';
import { FieldType, usersTableSchema } from 'topdekarze-tables';
import { useDispatch } from 'react-redux';
import { Main, ButtonsContainer } from '../../../components/Layout';
import { InteractiveTableSchema } from '../../../utils/table-schema-utils';
import { Button, ApiTable, TableWrapper } from '../../../components/Common';
import ApiService from '../../../services/api-service';
import { showModal, hideModal } from '../../../actions/modal';
import { refreshApiTable } from '../../../actions/api-table';
import { successNotification } from '../../../actions/notifications';
import { __ } from '../../../helpers/i18n';
import useHeader from '../../../hooks/useHeader';
import Confirmation from '../../../components/Modals/Confirmation';

import './Users.scss';
import { history } from '../../../App';

const Users: FC = () => {
  useHeader('dekarze', '/dashboard');
  const dispatch = useDispatch();
  const [allInTable, setAllInTable] = useState(true);

  const acceptUser = async (row: UserTableRes) => {
    await ApiService.callFetch(
      'PATCH',
      `superuser/accept-profile/${row.id}`,
      () => {
        dispatch(refreshApiTable());
        dispatch(successNotification(__('application.userAccepted')));
      },
      null,
    );
  };

  const rejectUser = async (row: UserTableRes) => {
    await ApiService.callFetch(
      'PATCH',
      `superuser/reject-profile/${row.id}`,
      () => {
        dispatch(refreshApiTable());
        dispatch(successNotification(__('application.userRejected')));
      },
      null,
    );
  };

  const sendReminder = async (row: UserTableRes) => {
    await ApiService.callFetch(
      'POST',
      `user/send-reminder/${row.id}`,
      () => {
        dispatch(successNotification(__('application.registrationReminderSentProperly')));
      },
      null,
    );
  };

  const handleReminder = (row: UserTableRes) => {
    dispatch(
      showModal(
        <Confirmation
          text={`Czy na pewno chcesz wysłać przypomnienie o rejestracji użytkownikowi: ${row.firstName} ${row.lastName}?`}
          confirmCallback={() => {
            dispatch(hideModal());
            sendReminder(row);
          }}
        />,
      ),
    );
  };

  const renderOtherActionsButtons = (row: UserTableRes) => {
    if (!row.isEnabled && row.isAccepted === null) {
      return (
        <ButtonsContainer>
          <Button text="Zaakceptuj" small success click={() => acceptUser(row)} />
          <Button text="Odrzuć" small danger click={() => rejectUser(row)} />
        </ButtonsContainer>
      );
    }
    return (
      <ButtonsContainer>
        <Button text="Podgląd" small success click={() => history.push(`/dashboard/users/preview/${row.id}`)} />
        {!row.rulesAccepted && <Button text="Przypomnij" small danger click={() => handleReminder(row)} />}
      </ButtonsContainer>
    );
  };
  const renderActionButton = (row: UserWithLogoTableRes) => (
    <ButtonsContainer>
      <Button text="Podgląd" small success click={() => history.push(`/dashboard/users/preview/${row.id}`)} />
    </ButtonsContainer>
  );

  const scheme = new InteractiveTableSchema(usersTableSchema)
    .addFieldAtTheEnd({
      name: 'Akcje',
      field: 'actions',
      type: FieldType.Custom,
      customRender: (value, field, row: UserTableRes) => renderOtherActionsButtons(row),
      mobile: true,
    })
    .removeFields('id', 'isModuleSelected', 'isInverterSelected', 'isConstructionSelected', 'isBoxSelected', 'isEnabled');

  const schemeLogo = new InteractiveTableSchema(usersTableSchema)
    .addFieldAtTheEnd({
      name: 'Akcje',
      field: 'actions',
      type: FieldType.Custom,
      customRender: (value, field, row: UserWithLogoTableRes) => renderActionButton(row),
      mobile: true,
    })
    .removeFields('id', 'isModuleSelected', 'isInverterSelected', 'isConstructionSelected', 'isBoxSelected', 'isEnabled');

  return (
    <Main className="main-users">
      <TableWrapper>
        <ButtonsContainer>
          <Button
            small
            text={allInTable ? 'Użytkownicy z wgranym logo' : 'Wszyscy użytkownicy'}
            click={() => {
              setAllInTable(!allInTable);
              dispatch(refreshApiTable());
            }}
          />
        </ButtonsContainer>
        <ApiTable
          scheme={allInTable ? scheme : schemeLogo}
          apiEndpointSubUrl={allInTable ? 'user/list' : 'user/users-with-logos'}
        />
      </TableWrapper>
    </Main>
  );
};

export default Users;
