import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Column } from '../../Layout';
import SocialsPanel from '../../Common/SocialsPanel';
import Icon from '../../Common/Icon';
import { __ } from '../../../helpers/i18n';

import './Footer.scss';

const Footer = () => (
  <footer className="footer">
    <Container>
      <div className="footer-container">
        <Row jBetween>
          <Column md={4}>
            <p className="footer-nav">
              <Link to="/contact-page">Formularz kontaktowy</Link>
            </p>
            <p className="footer-nav">
              <Link to="/rules">Regulamin programu</Link>
            </p>
          </Column>
          <Column md={4}>
            <p className="footer-nav">{__('application.followUs')}</p>
            <SocialsPanel />
          </Column>
          <Column md={4}>
            <Icon className="footer-creaton" icon="CREATON" />
          </Column>
        </Row>
        <Row className="privacy-panel">
          <Column md={4}>
            <p className="privacy">© Copyright CREATON Polska Sp. z o. o.</p>
          </Column>
          <Column md={4}>
            <Link to="/privacy-policy" className="privacy">
              Polityka prywatności
            </Link>
          </Column>
        </Row>
      </div>
    </Container>
  </footer>
);

export default Footer;
