import React, { FC } from 'react';
import { Main, ButtonsContainer, FormContainer } from '../../components/Layout';
import WelcomeText from '../../components/Common/WelcomeText';
import Button from '../../components/Common/Button';
import { __ } from '../../helpers/i18n';
import LoginForm from '../../components/Form/LoginForm';
import PageTitle from '../../components/Common/PageTitle';
import Brand from '../../components/Common/Brand';
import useHeader from '../../hooks/useHeader';

import './Home.scss';

const Home: FC = () => {
  useHeader('', '', true, true);

  return (
    <Main noPadding noLeft>
      <div className="home-page-wrapper">
        <div className="home-left">
          <div className="home-content">
            <div className="home-mobile">
              <Brand />
            </div>
            <div className="home-desktop">
              <WelcomeText big className="welcome-text-home">
                {__('application.welcome')}
                <small>{__('application.loginWelcome')}</small>
              </WelcomeText>
              <Button to="/register" light withBorder type="button" text={__('application.register')} />
            </div>
          </div>
        </div>
        <div className="home-right">
          <div className="home-content">
            <div className="home-desktop">
              <FormContainer>
                <PageTitle>{__('application.login')}</PageTitle>
                <LoginForm />
              </FormContainer>
            </div>
            <div className="home-mobile">
              <WelcomeText big className="welcome-text-home">
                {__('application.welcome')}
                <small>{__('application.loginWelcome')}</small>
              </WelcomeText>
              <ButtonsContainer>
                <Button to="/login" primary type="button" text={__('application.login')} />
                <Button to="/register" light withBorder type="button" text={__('application.register')} />
              </ButtonsContainer>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Home;
