import clsx from 'clsx';
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import './FormField.scss';

interface Props {
  type: 'text' | 'number' | 'file';
  name: string;
  labelText: string;
  field: 'input' | React.ReactNode;
  accept?: string;
  placeholderText?: string;
  labelClassname?: string;
  isRequired?: boolean;
  id?: string;
  handler?: () => void;
}

export const FormField: FC<Props> = ({
  type,
  labelClassname,
  labelText,
  isRequired,
  accept,
  name,
  id,
  placeholderText,
  field,
  handler,
  children,
}) => {
  const { register, errors } = useFormContext();
  return (
    <div className="form-field">
      <label htmlFor={id} className={clsx(labelClassname)}>
        {labelText}
        {children && children}
        {React.isValidElement(field) ? (
          field
        ) : (
          <>
            <input
              onChange={handler}
              type={type}
              name={name}
              id={id || name}
              ref={register(isRequired ? { required: 'Te pole jest wymagane' } : undefined)}
              placeholder={placeholderText}
              accept={accept}
            />
            <p className="formfield-input-error">{errors[name]?.message}</p>
          </>
        )}
      </label>
    </div>
  );
};
