import React from 'react';
import RegisterForm from '../../components/Form/RegisterForm';
import useHeader from '../../hooks/useHeader';
import WelcomeText from '../../components/Common/WelcomeText';
import { Main } from '../../components/Layout';
import DownContent from '../../components/Common/DownContent';

import './Register.scss';
import { __ } from '../../helpers/i18n';

const Register = () => {
  useHeader('Formularz rejestracyjny', '/', true);

  return (
    <Main noLeft>
      <DownContent
        form={<RegisterForm />}
        header={
          <WelcomeText className="register-welcome">
            {__('application.welcome')}
            <small>{__('application.registerWelcome')},</small>
          </WelcomeText>
        }
      />
    </Main>
  );
};

export default Register;
