import React, { useState } from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { AuthLoginResult, UserCommonEntity } from 'topdekarze-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { FormContainer, ButtonsContainer } from '../../Layout';
import { Button, Input, Spinner } from '../../Common';
import ApiService from '../../../services/api-service';
import { successNotification } from '../../../actions/notifications';
import UserDetails from '../../Common/UserDetails';
import SectionTitle from '../../Common/SectionTitle';
import { __ } from '../../../helpers/i18n';
import { formOnTranslateString } from '../../../utils/trans-form';

import './MyProfileForm.scss';

interface Props {
  user: UserCommonEntity;
}

const MyProfileForm = ({ user }: Props): JSX.Element => {
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();

  return (
    <FormContainer grid className={clsx('my-profile-form', { disabled: isDisabled })}>
      <ItfApiForm
        formId="my_profile_form"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        useFormDataInsteadOfPlainObject
        schemaFetcher={() => ApiService.loadForm(`user/form/update/${user?.id}`)}
        sendFetcher={(formId: string, values: any) => ApiService.sendForm(`user/update/${user?.id}`, values, 'PATCH')}
        submitButton={
          <ButtonsContainer>
            <Button disabled={isDisabled} type="submit" secondary text={__('application.update')} />
          </ButtonsContainer>
        }
        onTranslateString={formOnTranslateString}
        onSavedSuccessfully={(data: AuthLoginResult) => {
          setIsDisabled(true);
          dispatch(successNotification(__('application.dateUpdated')));
        }}
        onRenderFullField={(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur) => {
          switch (field.type) {
            case 'header':
              return (
                <>
                  <UserDetails className="my-profile-user-details" />
                  <div className="my-profile-section-header">
                    <SectionTitle>{__('application.yourData')}</SectionTitle>
                    <Button
                      click={() => setIsDisabled(!isDisabled)}
                      underline
                      reverse
                      noHover
                      secondary
                      text="Edytuj dane"
                    />
                  </div>
                </>
              );
            case 'text':
              return (
                <Input
                  type={field.options.type}
                  placeholder={field.placeholder}
                  label={field.title}
                  input={{
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    errors,
                    value: currentValue,
                    onChange: (e: any) => onChange(e.target.value),
                    onFocus,
                    onBlur,
                    disabled: isDisabled,
                  }}
                />
              );
            default:
              return null;
          }
        }}
      />
    </FormContainer>
  );
};

export default MyProfileForm;
