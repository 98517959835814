import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalState } from '../../../types/modal';
import { modal as modalActions } from '../../../actions';

import './Modal.scss';
import { RootState } from '../../../reducers';

interface Props {
  modal: ModalState;
  hideModal: () => void;
}

class Modal extends React.Component<Props> {
  private handleClickOutside = (e: any) => {
    const { modal, hideModal } = this.props;
    if (e.target.id === 'modal' && modal.isClosable) hideModal();
  };

  render() {
    const { modal } = this.props;
    if (!modal.isVisible && !modal.isPrepared) {
      return null;
    }
    return (
      <>
        <div className={`modal-overlay ${modal.isVisible ? 'visible' : ''}`} />
        <div
          className={`modal ${modal.isVisible ? 'visible' : ''} ${modal.isFullscreen ? 'full-screen' : ''}`}
          onClick={this.handleClickOutside}
          id="modal"
        >
          <div className="modal-body">
            <div className="modal-wrapper">{modal.content}</div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  modal: state.modal,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ hideModal: modalActions.hideModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
