import { UserAuthData } from 'topdekarze-types';

import ApiService from '../services/api-service';
import { notifications } from '../actions';

export const setUser = (user: UserAuthData) => ({ type: 'SET_USER', payload: user });

export const logout = () => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'LOGOUT',
    'LOGOUT',
    () => {
      dispatch(notifications.successNotification('Wylogowano poprawnie!'));
      dispatch({ type: 'REFRESH_API_TABLE' });
      dispatch({ type: 'REFRESH_API_GRID' });
    },
    null,
    'auth/logout',
  );
};

export const clearUserSession = () => ({ type: 'CLEAR_USER_SESSION' });
