import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from '../../../../reducers';
import { Container, Row, Column, Main, ButtonsContainer } from '../../../../components/Layout';
import useHeader from '../../../../hooks/useHeader';
import { AddMultiplePhoto, Button, InfoBox, PageTitle, Terms, Image } from '../../../../components/Common';
import useEvent from '../../../../hooks/events/useEvent';
import { sanitize } from '../../../../utils/sanitize';
import ApiService from '../../../../services/api-service';
import AddTransactionForm from '../../../../components/Form/AddTransactionForm';

import './SpecialActionPreview.scss';

const actions = [
  {
    id: 1,
    title: 'Promotor',
    image: 'promotor_akcja.jpg',
    description:
      'Bycie Top Dekarzem CREATON to powód do dumy! Pochwal się tym, że pracujesz z najlepszymi produktami CREATON, a zarazem promuj swoją firmę. Przygotujemy specjalnie dla Ciebie bannery, które będziesz mógł wykorzystywać podczas pracy. Startujemy od 15 września. <strong>Więcej szczegółów wkrótce!</strong>',
  },
  {
    id: 2,
    title: 'Tytani na dachu',
    image: 'tytani_dlugi.jpg',
    description:
      'Tylko od 16.09.2020 do 30.10.2020 r.  dodatkowo nagradzamy za realizacje  z dachówką ceramiczną TITANIA. <strong>Więcej szczegołów wkrótce!</strong>',
  },
];

const SpecialActionPreview = (): JSX.Element => {
  useHeader('', '/dashboard/special-tasks');
  const { eventId } = useParams<{ eventId: string }>();
  const images = useSelector((state: RootState) => state.multiplePhoto);
  const { event } = useEvent();
  const action = actions.find((a) => a.id === parseInt(eventId));
  const user = useSelector((state: RootState) => state.user.details);

  if (action) {
    return (
      <Main>
        <header className="action-header">
          <Image image={action?.image} />
        </header>
        <Container>
          <PageTitle>{action?.title}</PageTitle>
          <p dangerouslySetInnerHTML={{ __html: sanitize(action?.description) }} />
        </Container>
      </Main>
    );
  }

  return (
    <Main>
      <header className="action-header">
        <img src={`${ApiService.url}event/event-banner/${event?.id}`} alt={event?.title} />
        <InfoBox text="x" />
      </header>
      <Container className="special-action-form">
        <PageTitle>{event?.title}</PageTitle>
        <div dangerouslySetInnerHTML={{ __html: sanitize(event?.content) }} />
        {event?.quiz?.id && (
          <ButtonsContainer center className="special-action-quiz">
            <Button text="Quiz" to={`/dashboard/quizzes/view/${event?.quiz?.id}/start`} />
          </ButtonsContainer>
        )}
        {event?.termsFileFn && <Terms id={event?.id} name="Regulamin" />}
        {event?.displayTransactionForm && user?.role === event?.participant && (
          <div className="event-add-transaction">
            {!event?.isFormPhotoDisabled && (
              <Row>
                <AddMultiplePhoto />
              </Row>
            )}
            {event?.additionalEventInfo && (
              <Row>
                <p
                  className="additional-text-form"
                  dangerouslySetInnerHTML={{ __html: sanitize(event?.additionalEventInfo) }}
                />
              </Row>
            )}
            <Row>
              <Column className="add-description-module">
                <AddTransactionForm files={images.map((image) => image.file)} eventId={event?.id} />
              </Column>
            </Row>
          </div>
        )}
      </Container>
    </Main>
  );
};

export default SpecialActionPreview;
