import React from 'react';
import clsx from 'clsx';

import './Main.scss';

interface Props {
  children: React.ReactNode;
  dark?: boolean;
  alignEnd?: boolean;
  className?: string;
  noLeft?: boolean;
  noPadding?: boolean;
}

const Main = ({ children, dark, alignEnd, noLeft, noPadding, className }: Props) => (
  <main
    className={clsx({
      [`${className}`]: className,
      'dark-theme': dark,
      'align-end': alignEnd,
      'no-left': noLeft,
      'no-padding': noPadding,
    })}
  >
    {children}
  </main>
);

export default Main;
