import React, { ReactNode } from 'react';
import { Container } from '../../Layout';

import './TableWrapper.scss';

interface Props {
  children: ReactNode;
}

const TableWrapper = ({ children }: Props) => <Container className="table-wrapper">{children}</Container>;

export default TableWrapper;
