import React, { FC } from 'react';
import useHeader from '../../../hooks/useHeader';
import { Main, Container } from '../../../components/Layout';
import { TableWrapper } from '../../../components/Common';
import './TheEnd.scss';

const TheEnd: FC = () => {
  useHeader('Najlepsi top dekarze', '/');

  return (
    <Main className="the-end">
      <Container>
        <img className="wide-image" src="/assets/images/supernagroda-the-end.jpeg" alt="" />
        <p className="bigger">GRATULUJEMY NAJLEPSZYM 30 TOP DEKARZOM!!</p>
        <p>
          II edycja programu Top Dekarze CREATON dobiegła końca. Podliczyliśmy wszystkie punkty i z ogromną przyjemnością, informujemy Was o zwycięzcach!
        </p>
        <p>
          Dla przypomnienia dla TOP 10, przygotowaliśmy personalizowane zabudowy paki samochodu o wartości do poniższych
          kwot:
        </p>
        <p className="bigger">TOP 3 do 15 tys. zł</p>
        <TableWrapper>
          <table className="table">
            <thead>
              <th>Imię i nazwisko</th>
              <th>Ilość punktów</th>
            </thead>
            <tbody>
              <tr>
                <td>Daniel S.</td>
                <td>90 550</td>
              </tr>
              <tr>
                <td>Dawid P.</td>
                <td>78 930</td>
              </tr>
              <tr>
                <td>Tomasz M.</td>
                <td>77 880</td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
        <p className="bigger">TOP 4-6 do 10 tys. zł</p>
        <TableWrapper>
          <table className="table">
            <thead>
              <th>Imię i nazwisko</th>
              <th>Ilość punktów</th>
            </thead>
            <tbody>
              <tr>
                <td>Mirosław L.</td>
                <td>68 550</td>
              </tr>
              <tr>
                <td>Michał W.</td>
                <td>38 370</td>
              </tr>
              <tr>
                <td>Marcin Z.</td>
                <td>34 450</td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
        <p className="bigger">TOP 7-10 do 7 tys. zł</p>
        <TableWrapper>
          <table className="table">
            <thead>
              <th>Imię i nazwisko</th>
              <th>Ilość punktów</th>
            </thead>
            <tbody>
              <tr>
                <td>Marek P.</td>
                <td>34 440</td>
              </tr>
              <tr>
                <td>Kamil W.</td>
                <td>34 120</td>
              </tr>
              <tr>
                <td>Robert M.</td>
                <td>31 340</td>
              </tr>
              <tr>
                <td>Tomasz G.</td>
                <td>29 840</td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
        <img className="wide-image" src="/assets/images/supernagroda-the-end-2.jpeg" alt="" />
        <p>Kolejnych 20 Top Dekarzy wygrywa elektronarzędzia marki DeWALT!</p>
        <p className="bigger">TOP 11-20 DeWALT COMBO</p>
        <TableWrapper>
          <table className="table">
            <thead>
              <th>Imię i nazwisko</th>
              <th>Ilość punktów</th>
            </thead>
            <tbody>
              <tr>
                <td>Grzegorz P.</td>
                <td>26 600</td>
              </tr>
              <tr>
                <td>Paweł P.</td>
                <td>24 570</td>
              </tr>
              <tr>
                <td>Mariusz B.</td>
                <td>23 450</td>
              </tr>
              <tr>
                <td>Paweł M.</td>
                <td>23 290</td>
              </tr>
              <tr>
                <td>Michał C.</td>
                <td>23 270</td>
              </tr>
              <tr>
                <td>Dariusz Z.</td>
                <td>22 820</td>
              </tr>
              <tr>
                <td>Mateusz F.</td>
                <td>22 790</td>
              </tr>
              <tr>
                <td>Andrzej G.</td>
                <td>22 700</td>
              </tr>
              <tr>
                <td>Łukasz G.</td>
                <td>22 640</td>
              </tr>
              <tr>
                <td>Paweł B.</td>
                <td>22 530</td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
        <p className="bigger">TOP 21-30 DeWALT ZESTAW</p>
        <TableWrapper>
          <table className="table">
            <thead>
              <th>Imię i nazwisko</th>
              <th>Ilość punktów</th>
            </thead>
            <tbody>
              <tr>
                <td>Grzegorz M.</td>
                <td>22 120</td>
              </tr>
              <tr>
                <td>Tomasz B.</td>
                <td>20 660</td>
              </tr>
              <tr>
                <td>Daniel R.</td>
                <td>19 890</td>
              </tr>
              <tr>
                <td>Jacek K.</td>
                <td>19 490</td>
              </tr>
              <tr>
                <td>Mariusz CH.</td>
                <td>18 570</td>
              </tr>
              <tr>
                <td>Rafał C.</td>
                <td>18 570</td>
              </tr>
              <tr>
                <td>Grzegorz W.</td>
                <td>18 270</td>
              </tr>
              <tr>
                <td>Wojciech S.</td>
                <td>18 150</td>
              </tr>
              <tr>
                <td>Roman M.</td>
                <td>16 870</td>
              </tr>
              <tr>
                <td>Rafał M.</td>
                <td>16 470</td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
        <p>Wszystkim Wam bardzo serdecznie gratulujemy!</p>
        <p>Świetnie się bawiliśmy, mamy nadzieję, że Wy również?</p>
        <p>
          Ten program jest dla Was i bardzo zależy nam na Waszej opinii, dlatego, jeśli chcielibyście podzielić się nią  - wciąż zapraszamy do wypełnienia ankiety. W tej edycji nie wpłynie to już na wynik końcowy, ale na pewno pomoże uatrakcyjnić kolejną edycję!
        </p>
        <p>
          <a target="_blank" href="https://www.survio.com/survey/d/topdekarzecreaton" rel="noreferrer">
            ANKIETA
          </a>
        </p>
        <p>
          Tak, to już pewne! Od 1 września zaczynamy III edycje! Obserwujcie platformę, swoje skrzynki mailowe i telefony, 
          będziemy Was informować na bieżąco. 
        </p>
        <p>
          Mamy też dodatkową super aktywność dla Was na nadchodzące wakacje. Nasza Akademia Szkoleniowa CREATON już jeździ po Polsce odwiedzając dekarzy na placach budowy, z dużą dawką wiedzy, ciekawymi produktami, upominkami. Jeśli chcesz się z nami spotkać, wyślij zgłoszenie tutaj – link do akcji LATO z CREATONEM (ale spiesz się, ponieważ liczba wizyt jest ograniczona).
        </p>
        <p>Do zobaczenia Top Dekarze CREATON!</p>
      </Container>
    </Main>
  );
};

export default TheEnd;
