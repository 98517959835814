import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { EventRes } from 'topdekarze-types';
import ApiService from '../../services/api-service';

interface UseEvent {
  event: EventRes | undefined;
}

const useEvent = (): UseEvent => {
  const [event, setEvent] = useState<EventRes>();
  const { eventId } = useParams<{ eventId: string }>();

  const getEvent = async () => {
    await ApiService.callFetch('GET', `event/details/${eventId}`, (data: EventRes) => {
      setEvent(data);
    });
  };

  useEffect(() => {
    getEvent();
  }, []);

  return {
    event,
  };
};

export default useEvent;
