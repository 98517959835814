import { RealizationImages } from '../views/Dashboard/Transactions/AddTransaction';

export const setImages = ({ previewUrl, file }: RealizationImages) => ({
  type: 'SET_IMAGE',
  payload: {
    previewUrl,
    file,
  },
});

export const removePreviewImage = (previewUrl: string) => ({
  type: 'REMOVE_PREVIEW_IMAGE',
  payload: previewUrl,
});

export const clearPreviewImage = () => ({
  type: 'CLEAR_PREVIEW_IMAGE',
});
