import React from 'react';
import clsx from 'clsx';

import './Column.scss';

interface Props {
  children: React.ReactNode;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  vertical?: boolean;
  className?: string;
  hidden?: boolean;
  center?: boolean;
  as?: 'div' | 'li' | 'ul' | 'main' | 'section';
  mlA?: boolean;
  mrA?: boolean;
  onClick?: () => void;
  role?: string;
  autoWidth?: boolean;
  style?: Record<string, unknown>;
}

const Column = ({
  onClick,
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  vertical,
  className,
  hidden,
  mlA,
  mrA,
  as: Tag = 'div',
  role,
  autoWidth,
  style,
  center,
}: Props) => {
  const classes = clsx({
    [`col-xs-${xs}`]: xs,
    [`col-sm-${sm}`]: sm,
    [`col-md-${md}`]: md,
    [`col-lg-${lg}`]: lg,
    [`col-xl-${xl}`]: xl,
    [`${className}`]: className,
    vertical,
    hidden,
    center,
    'ml-a': mlA,
    'mr-a': mrA,
    auto: autoWidth,
  });
  return (
    <Tag style={style} role={role} onClick={onClick} className={`col ${classes}`}>
      {children}
    </Tag>
  );
};

export default Column;
