import React from 'react';
import { useSelector } from 'react-redux';
import useHeader from '../../../../hooks/useHeader';
import AddTransactionForDHTCHForm from '../../../../components/Form/AddTransactionForDHTCHForm';
import { Column, Container, Main, Row, ButtonsContainer } from '../../../../components/Layout';
import AddMultiplePhoto from '../../../../components/Common/AddMultiplePhoto';
import Button from '../../../../components/Common/Button';
import { RootState } from '../../../../reducers';

import '../AddTransaction/AddTransaction.scss';

const AddTransactionDHTCH = () => {
  useHeader('Dodaj zgłoszenie DHT Hot Challenge', '/dashboard');

  const images = useSelector((state: RootState) => state.multiplePhoto);
  return (
    <Main>
      <Container className="add-transition">
        <ButtonsContainer marginB>
          <Button small to="/dashboard/transactions/list" text="Weryfikacja zgłoszeń" />
        </ButtonsContainer>
        <Row>
          <AddMultiplePhoto />
        </Row>
        <Row>
          <Column>
            <AddTransactionForDHTCHForm files={images.map((image) => image.file)} />
          </Column>
        </Row>
      </Container>
    </Main>
  );
};

export default AddTransactionDHTCH;
