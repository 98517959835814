// @ts-nocheck
import React, { FC, useRef } from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { useDispatch } from 'react-redux';
import { FormFieldType } from 'itf_formbuilder';
import { AddEventReq } from 'topdekarze-types';
import flatpickr from 'flatpickr';
import Select from 'react-select';
import CKEditor from '@ckeditor/ckeditor5-react';
import CKEditorEditorBuild from 'topdekarze-ckeditor/dist/ckeditor';
import Creatable from 'react-select/creatable';
import { FormContainer, ButtonsContainer } from '../../Layout';
import { Button, Spinner, Input } from '../../Common';
import ApiService from '../../../services/api-service';
import { __ } from '../../../helpers/i18n';
import { formOnTranslateString } from '../../../utils/trans-form';
import { successNotification } from '../../../actions/notifications';

import './AddSpecialActionForm.scss';

const AddSpecialActionForm: FC = () => {
  const dispatch = useDispatch();
  const ref = useRef<ItfApiForm>(null);

  return (
    <FormContainer className="special-action-form">
      <ItfApiForm
        ref={ref}
        formId="addSpecialActionForm"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        useFormDataInsteadOfPlainObject
        schemaFetcher={() => ApiService.loadForm('event/form/add-special-event')}
        sendFetcher={(formId: string, values: AddEventReq) => ApiService.sendForm('event/add', values)}
        submitButton={
          <ButtonsContainer>
            <Button primary type="submit" text={__('application.add')} />
          </ButtonsContainer>
        }
        thirdPartyComponents={{
          flatpickr,
          ReactSelect: Select,
          ReactCreatableSelect: Creatable,
          CKEditor,
          CKEditorEditorBuild,
        }}
        onRenderFullField={(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur) => {
          switch (field.type) {
            case FormFieldType.Text:
            case FormFieldType.Textarea:
              return (
                <Input
                  type={field.options.type || field.type}
                  placeholder={field.placeholder}
                  label={field.title}
                  input={{
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    errors,
                    value: currentValue,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
                    onFocus,
                    onBlur,
                  }}
                />
              );
            case FormFieldType.Number:
              return (
                <Input
                  type={field.options.type || field.type}
                  placeholder={field.placeholder}
                  label={field.title}
                  input={{
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    errors,
                    value: currentValue,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
                    onFocus,
                    onBlur,
                  }}
                />
              );
            default:
              return null;
          }
        }}
        onTranslateString={formOnTranslateString}
        onSavedSuccessfully={() => {
          dispatch(successNotification(__('application.addedSpecialTask')));
          if (ref.current) ref.current.api.resetValues();
        }}
      />
    </FormContainer>
  );
};

export default AddSpecialActionForm;
