import React, { FC } from 'react';
import { ArticleRes } from 'topdekarze-types';
import { Link } from 'react-router-dom';
import { Container, Column, Row } from '../../Layout';
import ApiService from '../../../services/api-service';
import Image from '../Image';
import PageTitle from '../PageTitle';

import './HomeNewsDesktop.scss';

interface Props {
  content: ArticleRes[];
}

const HomeNewsDesktop: FC<Props> = ({ content }) => (
  <section>
    <Container>
      <Row className="news-box-wrapper">
        <Column xs={12}>
          <PageTitle>Aktualności</PageTitle>
        </Column>
        <Column className="left-side" xs={8}>
          {/* <Link to="/dashboard/main-prize" className="news-box main">
            <Image image="baner_novaPaka_v3.jpg" />
          </Link> */}
          {/* <Link to="/dashboard/tytani-wyniki" className="news-box main">
            <Image image="tytani_wyniki_baner.jpg" />
          </Link> */}
          {/* <Link to="/dashboard/womens-day" className="news-box main">
            <Image image="womens_day_winners.jpeg" />
          </Link> */}
          {/* <Image image="wielkanoc_kartka.jpeg" /> */}
          {/* <Link to="/dashboard/promotor-2-finish" className="news-box main">
            <Image image="poznaj_zwyciezcow_promotor_ii.jpeg" />
          </Link> */}
          <Link to="/dashboard/articles/view/ec968269-918e-4dd3-ac2f-de5e048e8491" className="news-box main">
            <Image image="creaton_3.jpeg" />
          </Link>
        </Column>
        <Column className="right-side" xs={4}>
          <Row>
            {content &&
              content.length > 0 &&
              content
                .filter((b) => !['ec968269-918e-4dd3-ac2f-de5e048e8491'].includes(b.id))
                .map((singleBox) => (
                  <Column xs={12} className="news-box-column" key={singleBox.id}>
                    <Link to={`/dashboard/articles/view/${singleBox.id}`} className="news-box">
                      <img src={`${ApiService.url}article/image/${singleBox.id}`} alt="" />
                      <div className="title">{singleBox.title}</div>
                    </Link>
                  </Column>
                ))}
          </Row>
        </Column>
      </Row>
    </Container>
  </section>
);

export default HomeNewsDesktop;
