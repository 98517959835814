interface SetHeader {
  type: 'SET_HEADER';
  payload: HeaderState;
}

type Action = SetHeader;

export interface HeaderState {
  title: string;
  back: string;
  noLeft?: boolean;
  hideOnMobile?: boolean;
}

const initialState: HeaderState = {
  title: '',
  back: '',
  noLeft: false,
  hideOnMobile: false,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_HEADER': {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
