import React from 'react';
import { useSelector } from 'react-redux';
import useHeader from '../../../../hooks/useHeader';
import AddTransactionForm from '../../../../components/Form/AddTransactionForm';
import { Column, Container, Main, Row } from '../../../../components/Layout';
import { SectionTitle } from '../../../../components/Common';
import { RootState } from '../../../../reducers';

import './AddTransaction.scss';

export type RealizationImages = {
  previewUrl: string;
  file: File;
};

const AddTransaction = () => {
  useHeader('Dodaj realizacje', '/dashboard');

  // const images = useSelector((state: RootState) => state.multiplePhoto);

  return (
    <Main>
      <Container className="add-transition">
        <SectionTitle>Program Top Dekarze CREATON dobiegł końca.</SectionTitle>
        <p>Wgrywanie realizacji zostało zablokowane.</p>
        {/* <Row>
          <AddMultiplePhoto />
        </Row>
        <Row>
          <Column className="add-description-module">
            <AddTransactionForm files={images.map((image) => image.file)} />
          </Column>
        </Row> */}
      </Container>
    </Main>
  );
};

export default AddTransaction;
