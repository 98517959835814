import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { UserRole } from 'topdekarze-types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import RouteAdmin from '../../../components/Routes/RouteAdmin';
import MyHistory from './MyHistory';
import UserHistrory from './UserHistory';

const History: FC = () => {
  const user = useSelector((state: RootState) => state.user.details);
  return (
    <Switch>
      <Route
        exact
        path="/dashboard/points-history"
        render={() =>
          user?.role === UserRole.Admin ? (
            <Redirect to="/dashboard/points-history/:userId" />
          ) : (
            <Redirect to="/dashboard/points-history/me" />
          )
        }
      />
      <Route exact path="/dashboard/points-history/me" component={MyHistory} />
      <RouteAdmin path="/dashboard/points-history/:userId" component={UserHistrory} />
    </Switch>
  );
};

export default History;
