import React, { FC } from 'react';
import useHomeNews from '../../../../hooks/useHomeNews';
import HomeNewsMobile from '../../../../components/Common/HomeNewsMobile';
import HomeNewsDesktop from '../../../../components/Common/HomeNewsDesktop';
import PageTitle from '../../../../components/Common/PageTitle';

const HomeNews: FC<any> = () => {
  const { isLoading, isMobile, article } = useHomeNews();
  return <>{isMobile ? <HomeNewsMobile content={article} /> : <HomeNewsDesktop content={article} />}</>;
};

export default HomeNews;
