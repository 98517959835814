import React from 'react';
import { Main, Container, Row, Column, ButtonsContainer } from '../../../components/Layout';
import InviteUserForm from '../../../components/Form/InviteUserForm';
import useHeader from '../../../hooks/useHeader';
import DownContent from '../../../components/Common/DownContent';
import { Button, SectionTitle } from '../../../components/Common';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { UserRole } from 'topdekarze-types';

const InviteUser = () => {
  useHeader('Wyślij zaproszenie', '/dashboard');
  const user = useSelector((state: RootState) => state.user.details);
  const isUser = user?.role === UserRole.User;

  return (
    <Main>
      <Container>
        {!isUser ? (
          <>
            <ButtonsContainer>
              <Button small to="/dashboard/invitations" text="Lista zaproszeń" />
            </ButtonsContainer>
            <InviteUserForm />
          </>
        ) : (
          <>
            <SectionTitle>Program Top Dekarze CREATON dobiegł końca.</SectionTitle>
            <p>Wysyłanie zaproszeń zostało zablokowane.</p>
          </>
        )}
      </Container>
    </Main>
  );
};

export default InviteUser;
