import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { RootState } from '../../../reducers';
import Icon from '../Icon';
import Brand from '../../Common/Brand';
import { Container } from '../../Layout';
import Logout from '../Logout';

import './Header.scss';

const Header = () => {
  const { title, back, noLeft, hideOnMobile } = useSelector((state: RootState) => state.header);
  const user = useSelector((state: RootState) => state.user.details);

  return (
    <>
      <header
        className={clsx('page-header', { narrow: !title.length, 'no-left': noLeft, 'hide-on-mobile': hideOnMobile })}
      >
        <Container>
          <div className="header-content">
            {back ? (
              <Link to={back} className="back-button">
                <Icon big icon="back-arrow" />
              </Link>
            ) : user ? (
              <div className="header-logout">
                <Logout />
              </div>
            ) : (
              <span />
            )}
            {!!title && <h1 className="page-header-title">{title}</h1>}

            <div className="header-brand">
              <Brand />
            </div>
          </div>
        </Container>
      </header>
    </>
  );
};

export default Header;
