import React from 'react';
import ReactPaginate from 'react-paginate';
import { Button } from '..';
import './Pagination.scss';

interface Props {
  pageCount: number;
  initialPage?: number;
  onPageChange?: (page: number) => void;
}
const Pagination = ({ pageCount, initialPage, onPageChange }: Props) => {
  if (pageCount < 2) return null;
  return (
    <ReactPaginate
      containerClassName="pagination pagination-mobile"
      pageLinkClassName="button button-link"
      activeLinkClassName="button-link-active"
      previousLinkClassName="button-active-primary"
      nextLinkClassName="button-active-primary"
      disabledClassName="button-link disabled"
      onPageChange={({ selected }: { selected: number }) => onPageChange && onPageChange(selected + 1)}
      previousLabel={<Button text="<" />}
      nextLabel={<Button text=">" />}
      initialPage={(initialPage || 1) - 1}
      pageCount={pageCount}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
    />
  );
};
export default Pagination;
