import React, { ReactNode } from 'react';
import clsx from 'clsx';

import './WelcomeText.scss';

interface Props {
  children: ReactNode;
  light?: boolean;
  big?: boolean;
  className?: string;
}

const WelcomeText = ({ className, children, light, big }: Props) => (
  <p className={clsx('welcome-text', { [`${className}`]: className, light, big })}>{children}</p>
);

export default WelcomeText;
