import { useState, useEffect } from 'react';
import ApiService from '../services/api-service';

const useActionsGallery = () => {
  const [actionsWithGallery, setActionsWidthGallery] = useState<any>();

  const getActionsWithGallery = async () => {
    await ApiService.callFetch('GET', 'event/all', (data: any) => {
      setActionsWidthGallery(data);
    });
  };

  useEffect(() => {
    getActionsWithGallery();
  }, []);

  return {
    actionsWithGallery,
  };
};
export default useActionsGallery;
