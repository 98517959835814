/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import useArticlePreview, { Mode } from '../../../../hooks/articles/useArticlePreview';
import { Column, Container, Main, Row, ButtonsContainer } from '../../../../components/Layout';
import { Alert, Button, PageTitle, ChangeMode } from '../../../../components/Common';
import AddArticlesForm from '../../../../components/Form/AddArticlesForm';
import { sanitize } from '../../../../utils/sanitize';
import { history } from '../../../../App';
import { __ } from '../../../../helpers/i18n';

import './Preview.scss';

const ArticlePreview: FC = () => {
  const { article, isLoading, getPhoto, changeMode, mode, isAdmin } = useArticlePreview();
  let content = <Alert type="error" text="Nie znaleziono żądanego artykułu." />;

  if (article) {
    content = (
      <>
        {isAdmin && (
          <Row>
            <ChangeMode mode={mode} changeMode={changeMode} />
          </Row>
        )}
        <Row>
          {isLoading ? (
            <Column xs={12}>
              <Alert type="notice" text={__('application.loading')} />
            </Column>
          ) : mode === Mode.Preview ? (
            <>
              <Column>
                <PageTitle>{article.title}</PageTitle>
              </Column>
              <Column className="img-wrapper">
                <img className="preview-main-photo" src={getPhoto()} alt="" />
              </Column>
              <div dangerouslySetInnerHTML={{ __html: article.content }} className="article-content" />
              {article.quiz && (
                <Column center>
                  <ButtonsContainer center>
                    <Button
                      secondary
                      click={() => history.push(`/dashboard/quizzes/view/${article.quiz.id}/start`)}
                      text={__('application.runQuiz')}
                    />
                  </ButtonsContainer>
                </Column>
              )}
            </>
          ) : (
            <AddArticlesForm articleId={article.id} />
          )}
        </Row>
      </>
    );
  }

  return (
    <Main>
      <Container>{content}</Container>
    </Main>
  );
};

export default ArticlePreview;
