import React from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { AuthLoginResult, UserRegisterReq } from 'topdekarze-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import FormContainer from '../../Layout/FormContainer';
import { Spinner, Button } from '../../Common';
import ApiService from '../../../services/api-service';
import { successNotification } from '../../../actions/notifications';
import { history } from '../../../App';
import { __ } from '../../../helpers/i18n';
import { formOnTranslateString } from '../../../utils/trans-form';

import '../RegisterForm/RegisterForm.scss';

const RegisterInvitedForm = (): JSX.Element => {
  const { tokenId } = useParams();
  const dispatch = useDispatch();
  return (
    <FormContainer>
      <ItfApiForm
        formId="registerInvitedForm"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        onTranslateString={formOnTranslateString}
        schemaFetcher={() => ApiService.loadForm('user/form/invitation-register')}
        sendFetcher={(formId: string, values: UserRegisterReq) =>
          ApiService.sendForm('user/register-invite', { ...values, id: tokenId })
        }
        thirdPartyComponents={{
          ReactSelect: Select,
          ReactCreatableSelect: Creatable,
        }}
        submitButton={<Button primary type="submit" text={__('application.register')} />}
        onSavedSuccessfully={(data: AuthLoginResult) => {
          dispatch(successNotification(`${__('application.registerInvitedCorrect')}`));
          history.push('/login');
        }}
      />
    </FormContainer>
  );
};

export default RegisterInvitedForm;
