import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../../actions/user';
import { RootState } from '../../../reducers';
import Spinner from '../Spinner';

interface Props {
  children: React.ReactNode;
}

const AppWrapper = ({ children }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.details);
  const loading = useSelector((state: RootState) => state.user.loading);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  // if (loading) return <Spinner overlay light />;

  return <>{children}</>;
};

export default AppWrapper;
