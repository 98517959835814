import { useEffect, useState } from 'react';
import { UserEntity } from 'topdekarze-types';
import ApiService from '../services/api-service';

const useMySuperuser = () => {
  const [superUser, setSuperuser] = useState<UserEntity>();
  const getMySuperuser = async () => {
    await ApiService.callFetch(
      'GET',
      'user/my-superuser',
      (data: UserEntity) => {
        setSuperuser(data);
      },
      null,
    );
  };

  useEffect(() => {
    getMySuperuser();
  }, []);

  return { superUser };
};

export default useMySuperuser;
