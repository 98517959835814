import React, { useState, useEffect, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { WorkBoxProvider } from 'react-workbox';
import { createBrowserHistory } from 'history';
import flatpickr from 'flatpickr';
import { Polish } from 'flatpickr/dist/l10n/pl';
import { Register, Home, ResetPassword, Login, RegisterInvited, WelcomePage, PrivacyPolicy, Rules } from './views';
import { OnlineContent, OfflineContent, Header, UpdateInfoBar, Spinner, Modal, AppWrapper } from './components/Common';
import { NotificationContainer } from './components/Notifications';
import AppOffline from './components/AppOffline';
import WelcomePageRedirect from './components/WelcomePageRedirect';
import RegisterInvitationRedirect from './components/RegisterInvitationRedirect';
import { i18n } from './helpers/i18n';
import { appStatus } from './actions/app-status';
import { store } from './config/store';
import RouteUnauthorized from './components/Routes/RouteUnauthorized';
import RouteAuthorized from './components/Routes/RouteAuthorized';
import { scrollTop } from './utils/scroll-top';

import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/airbnb.css';

export { flatpickr };
flatpickr.localize(Polish);
flatpickr.defaultConfig.time_24hr = true;

export const history = createBrowserHistory({ basename: '/' });

const Dashboard = React.lazy(() => import('./views/Dashboard'));
const Page404 = React.lazy(() => import('./views/Page404'));
const ContactPage = React.lazy(() => import('./views/ContactPage'));

history.listen((location) => scrollTop());

const App = () => {
  const [loading, setLoading] = useState(true);
  const checkI18nLoaded = () => {
    if (i18n.loaded) {
      setLoading(false);
    } else {
      setTimeout(checkI18nLoaded, 100);
    }
  };

  useEffect(() => {
    const lang = store.getState().lang.current;
    i18n.load(lang);
    checkI18nLoaded();
  }, []);

  if (loading) return <Spinner overlay />;

  return (
    <WorkBoxProvider>
      <Provider store={store}>
        <OnlineContent>
          <UpdateInfoBar />
          <NotificationContainer />
          <AppWrapper>
            <Router history={history}>
              <Header />
              <Suspense fallback={<Spinner overlay />}>
                <Switch>
                  <Route exact path="/rules" component={Rules} />
                  <RouteUnauthorized exact path="/" component={Home} />
                  <RouteUnauthorized exact path="/register" component={Register} />
                  <RouteUnauthorized exact path="/login" component={Login} />
                  <Route path="/w/:userId/:updateToken" component={WelcomePageRedirect} />
                  <Route path="/welcome/first-login/:userId/:updateToken" component={WelcomePage} />
                  <Route path="/r/:tokenId" component={RegisterInvitationRedirect} />
                  <Route path="/register-invitation/:tokenId" component={RegisterInvited} />
                  <RouteAuthorized path="/dashboard" component={Dashboard} />
                  <Route path="/privacy-policy" component={PrivacyPolicy} />
                  <Route path="/rules" component={Rules} />
                  <Route path="/404" component={Page404} />
                  <Route path="/restore-pass" component={ResetPassword} />
                  <Route exact path="/contact-page" component={ContactPage} />
                  <Route render={() => <Redirect to="/404" />} />
                </Switch>
              </Suspense>
            </Router>
          </AppWrapper>
          <Modal />
        </OnlineContent>
        <OfflineContent>
          <AppOffline />
        </OfflineContent>
      </Provider>
    </WorkBoxProvider>
  );
};

const listenToWindowEvent = (name: string, mapEventToAction: any) => (dispatch: any) => {
  const handleEvent = (e: any) => {
    dispatch(mapEventToAction(e));
  };
  window.addEventListener(name, handleEvent);
};

store.dispatch(listenToWindowEvent('offline', appStatus));

store.dispatch(listenToWindowEvent('online', appStatus));

store.dispatch(appStatus({ type: window.navigator.onLine ? 'online' : 'offline' }));

export default App;
