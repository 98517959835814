import { useState } from 'react';

interface UseAddArticle {
  handleEditorChange: (text: string) => void;
  content: string | null;
  returnFormSettings: (articleId?: string) => AddArticleFormSettings;
}

export interface AddArticleFormSettings {
  sendMethod: string;
  sendUrl: string;
  fetchUrl: string;
  buttonText: string;
  notification: string;
}

const useAddArticle = (): UseAddArticle => {
  const [content, setContent] = useState<string | null>(null);

  const handleEditorChange = (text: string) => {
    setContent(text);
  };

  const returnFormSettings = (articleId?: string): AddArticleFormSettings => {
    if (articleId) {
      return {
        sendMethod: 'PATCH',
        sendUrl: `article/edit/${articleId}`,
        fetchUrl: `article/form/${articleId}`,
        buttonText: 'application.editArticle',
        notification: 'Zaktualizowano artykuł',
      };
    } else {
      return {
        sendMethod: 'POST',
        sendUrl: 'article/add',
        fetchUrl: 'article/form/add',
        buttonText: 'application.addArticle',
        notification: 'Dodano artykuł',
      };
    }
  };

  return { handleEditorChange, content, returnFormSettings };
};

export default useAddArticle;
