import React, { FC } from 'react';
import { ItfApiForm } from 'itf_formbuilder_react';
import { useDispatch } from 'react-redux';
import FormContainer from '../../Layout/FormContainer';
import { Button, Spinner } from '../../Common';
import ApiService from '../../../services/api-service';
import { __ } from '../../../helpers/i18n';
import { successNotification } from '../../../actions/notifications';
import { formOnTranslateString } from '../../../utils/trans-form';
import { ButtonsContainer } from '../../Layout';
import './ImportCsvForm.scss';

interface ImportCsv extends FormData {
  importedUsers: string;
  csv: File;
}

const ImportCsvForm: FC = () => {
  const dispatch = useDispatch();

  return (
    <FormContainer grid className="import-csv">
      <ItfApiForm
        formId="importCsvForm"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        schemaFetcher={() => ApiService.loadForm('admin/form/import')}
        useFormDataInsteadOfPlainObject
        onTranslateString={formOnTranslateString}
        sendFetcher={(formId: string, values: ImportCsv) => {
          ApiService.sendForm(`admin/import-${values.get('importedUsers')}`, values);
        }}
        submitButton={
          <ButtonsContainer>
            <Button secondary type="submit" text={__('application.import')} />
          </ButtonsContainer>
        }
        onSavedSuccessfully={() => {
          dispatch(successNotification(__('application.fileImported')));
        }}
      />
    </FormContainer>
  );
};

export default ImportCsvForm;
