import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Button.scss';

interface ButtonProps {
  text?: string;
  to?: string;
  click?: any;
  primary?: boolean;
  secondary?: boolean;
  light?: boolean;
  danger?: boolean;
  success?: boolean;
  mainColor?: boolean;
  type?: string;
  big?: boolean;
  small?: boolean;
  disabled?: boolean;
  blank?: boolean;
  className?: string;
  faIcon?: any;
  leftIcon?: boolean;
  rightIcon?: boolean;
  round?: boolean;
  withBorder?: boolean;
  reverse?: boolean;
  underline?: boolean;
  noHover?: boolean;
  mlA?: boolean;
  download?: boolean;
  children?: React.ReactNode;
}

enum ButtonType {
  Submit = 'submit',
  Button = 'button',
  Reset = 'reset',
}

const Button = (props: ButtonProps): any => {
  const {
    children,
    className,
    primary,
    light,
    small,
    big,
    disabled,
    blank,
    to,
    type,
    click,
    text,
    secondary,
    faIcon,
    rightIcon,
    leftIcon,
    round,
    danger,
    success,
    withBorder,
    reverse,
    underline,
    noHover,
    mainColor,
    mlA,
    download,
  } = props;

  const setButtonType = (userinput = 'button') => {
    switch (userinput) {
      case ButtonType.Button:
        return 'button';
      case ButtonType.Submit:
        return 'submit';
      default:
        return 'button';
    }
  };

  const buttonClass = `button
    ${className || ''} ${primary ? 'button-primary' : ''} 
    ${secondary ? 'button-secondary' : ''} 
    ${light ? 'button-light' : ''} 
    ${big ? 'button-big' : ''} 
    ${small ? 'button-small' : ''}
    ${rightIcon ? 'button-right-icon' : ''} ${leftIcon ? 'button-left-icon' : ''} 
    ${disabled ? 'button-disabled' : ''}
    ${round ? 'button-round' : ''}
    ${mainColor ? 'button-main' : ''}
    ${noHover ? 'no-hover' : ''}
    ${danger ? 'button-danger' : ''}
    ${success ? 'button-success' : ''}
    ${withBorder ? 'with-border' : ''}
    ${reverse ? 'reverse' : ''}
    ${underline ? 'underline' : ''}
    ${mlA ? 'ml-a' : ''}
    ${!text ? 'button-no-text' : ''}
    `;

  if (to) {
    if (disabled) {
      return <a className={buttonClass}>{text}</a>;
    }
    if (blank) {
      return (
        <a target="_blank" download={download} rel="noopener noreferrer" className={buttonClass} href={to}>
          {text}
        </a>
      );
    }
    return (
      <Link className={buttonClass} to={to}>
        {leftIcon && faIcon ? (
          <div className="button-icon">
            <FontAwesomeIcon icon={faIcon} />
          </div>
        ) : null}
        {text}
        {rightIcon && faIcon ? (
          <div className="button-icon">
            <FontAwesomeIcon icon={faIcon} />
          </div>
        ) : null}
      </Link>
    );
  }

  return (
    <button type={setButtonType(type) || 'button'} disabled={disabled} onClick={click} className={buttonClass}>
      {leftIcon && faIcon ? (
        <div className="button-icon">
          <FontAwesomeIcon icon={faIcon} />
        </div>
      ) : null}
      {rightIcon && faIcon ? (
        <div className="button-icon">
          <FontAwesomeIcon icon={faIcon} />
        </div>
      ) : null}
      {text}
      {children}
    </button>
  );
};

export default Button;
