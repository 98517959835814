import React, { FC } from 'react';
import { UserTableRes } from 'topdekarze-types';
import { FieldType, usersTableSchema } from 'topdekarze-tables';
import { useDispatch } from 'react-redux';
import { Main, ButtonsContainer } from '../../../components/Layout';
import ApiTable from '../../../components/Common/ApiTable';
import { InteractiveTableSchema } from '../../../utils/table-schema-utils';
import { Button } from '../../../components/Common';
import ApiService from '../../../services/api-service';
import { refreshApiTable } from '../../../actions/api-table';
import { successNotification } from '../../../actions/notifications';
import { __ } from '../../../helpers/i18n';
import useHeader from '../../../hooks/useHeader';
import TableWrapper from '../../../components/Common/TableWrapper';

const VerifyUsers: FC = () => {
  useHeader('Zweryfikuj dekarzy', '/dashboard');
  const dispatch = useDispatch();

  const acceptUser = async (row: UserTableRes) => {
    await ApiService.callFetch(
      'PATCH',
      `superuser/accept-profile/${row.id}`,
      () => {
        dispatch(refreshApiTable());
        dispatch(successNotification(__('application.userAccepted')));
      },
      null,
    );
  };

  const rejectUser = async (row: UserTableRes) => {
    await ApiService.callFetch(
      'PATCH',
      `superuser/reject-profile/${row.id}`,
      () => {
        dispatch(refreshApiTable());
        dispatch(successNotification(__('application.userRejected')));
      },
      null,
    );
  };

  const renderOtherActionsButtons = (row: UserTableRes) => {
    if (!row.isEnabled && row.isAccepted === null) {
      return (
        <ButtonsContainer>
          <Button text="Zaakceptuj" small success click={() => acceptUser(row)} />
          <Button text="Odrzuć" small danger click={() => rejectUser(row)} />
        </ButtonsContainer>
      );
    }
    return null;
  };

  const scheme = new InteractiveTableSchema(usersTableSchema)
    .addFieldAtTheEnd({
      name: 'Akcje',
      field: 'actions',
      type: FieldType.Custom,
      customRender: (value, field, row: UserTableRes) => renderOtherActionsButtons(row),
      mobile: true,
    })
    .removeFields(
      'id',
      'isModuleSelected',
      'isInverterSelected',
      'isConstructionSelected',
      'isBoxSelected',
      'isAccepted',
    );

  return (
    <Main className="main-users">
      <TableWrapper>
        <ApiTable scheme={scheme} apiEndpointSubUrl="superuser/find-my-users" />
      </TableWrapper>
    </Main>
  );
};

export default VerifyUsers;
