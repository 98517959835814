import React, { FC } from 'react';
import clsx from 'clsx';
import Icon from '../../../../components/Common/Icon';
import './DashboardItem.scss';
import { Link } from 'react-router-dom';

interface Props {
  itemIcon?: string;
  itemValue?: number;
  itemName?: string;
  to?: string;
}

const DashboardItem: FC<Props> = ({ itemIcon, itemValue, itemName, to }) => {
  const content = (
    <div className={clsx('item', { column: !itemValue })}>
      <Icon icon={itemIcon} big className="dashboard-icon" />
      <div className="content">
        {itemValue ? <span className="item-value">{itemValue}</span> : ''}
        <span className="item-name">{itemName}</span>
      </div>
    </div>
  );
  return to ? (
    <Link to={to} className="dashboard-item-wrapper">
      {content}
    </Link>
  ) : (
    <div className="dashboard-item-wrapper">{content}</div>
  );
};

export default DashboardItem;
