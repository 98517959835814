import React from 'react';
import ApiService from '../../../services/api-service';
import Icon from '../Icon';

import './Terms.scss';
import Button from '../Button';
import { ButtonsContainer } from '../../Layout';

interface Props {
  id: string;
  name: string;
}

const Terms = ({ id, name }: Props) => (
  <ButtonsContainer center>
    <Button blank big to={`${ApiService.url}event/event-terms/${id}`} text={name} />
  </ButtonsContainer>
);

export default Terms;
