import React from 'react';
import clsx from 'clsx';
import './InfoBox.scss';

interface InfoBoxProps {
  text: string;
  className?: string;
}

const InfoBox = ({ className, text }: InfoBoxProps) => (
  <div className={clsx('info-box', { [`${className}`]: className })}>{text}</div>
);

export default InfoBox;
