import React from 'react';
import clsx from 'clsx';

import './Container.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  mode?: 'light' | 'dark';
  fluid?: boolean;
}
const Container = ({ children, className, mode, fluid }: Props): JSX.Element => (
  <div
    className={clsx('container ', {
      [`${className}`]: className,
      dark: mode === 'dark',
      light: mode === 'light',
      fluid,
    })}
  >
    {children}
  </div>
);

export default Container;
