import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import Start from './Start';
import MyProfile from './MyProfile';
import MyUsers from './MyUsers';
import VerifyUsers from './VerifyUsers';
import Users from './Users';
import InviteUser from './InviteUser';
import Invitations from './Invitations';
import UsersImporting from './UsersImporting';
import MessagesManagement from './MessagesManagement';
import { DashboardNav } from '../../types/navs';
import MobileNav from '../../components/Common/MobileNav';
import Footer from '../../components/Common/Footer';
import DesktopNav from '../../components/Common/DesktopNav';
import AddTransaction from './Transactions/AddTransaction';
import AddTransactionDHTCH from './Transactions/AddTransactionDHTCH';
import AllTransactions from './Transactions/AllTransactions';
import Transaction from './Transactions/Transaction';
import RouteSuperUser from '../../components/Routes/RouteSuperUser';
import RouteAdmin from '../../components/Routes/RouteAdmin';
import MainRating from './Rating/MainRating';
import History from './History';
import Quizzes from './Quizzes';
import SpecialActions from './SpecialActions';
import Articles from './Articles';
import RouteAuthorized from '../../components/Routes/RouteAuthorized';
import DHTHotChallenge from './DHTHotChallenge';
import MainPrize from './MainPrize';
// import MikolajFinish from './MikolajFinish';
// import Christmas2020 from './Christmas2020';
// import SimplaFinish from './SimplaFinish';
import { CompetitionRules } from '..';
import UsersPreview from './Users/Preview';
// import PromotorFinish from './PromotorFinish';
import PromotorSecondFinish from './PromotorSecondFinish';
import KeralisFinish from './KeralisFinish';
import NovaPaka from './NovaPaka';
import WomensDay from './WomensDay';
import TheEnd from './TheEnd';
import CementoweFinish from './CementoweFinish';

const nav: DashboardNav[] = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    iconName: 'home',
    tooltip: 'Widok główny',
  },
  {
    text: 'Mój profil',
    path: '/dashboard/my-profile',
    iconName: 'user',
    tooltip: 'Mój profil',
  },
  {
    text: 'Info',
    path: '/dashboard/competition-rules',
    iconName: 'info',
    tooltip: 'Informacje o konkursie',
  },
];

const Dashboard: FC = () => (
  <>
    <DesktopNav menuItems={nav} />
    <Switch>
      <Route exact path="/dashboard" component={Start} />
      <Route exact path="/dashboard/my-profile" component={MyProfile} />
      <Route exact path="/dashboard/my-users" component={MyUsers} />
      <Route exact path="/dashboard/users" component={Users} />
      <Route exact path="/dashboard/verify-users" component={VerifyUsers} />
      <Route exact path="/dashboard/transactions/add" component={AddTransaction} />
      <Route exact path="/dashboard/invite-user" component={InviteUser} />
      <RouteSuperUser exact path="/dashboard/transactions/list" component={AllTransactions} />
      <RouteSuperUser path="/dashboard/transactions/list/:id" component={Transaction} />
      <RouteAdmin exact path="/dashboard/transactions/add-dht-h-c" component={AddTransactionDHTCH} />
      <Route exact path="/dashboard/invitations" component={Invitations} />
      <RouteAuthorized path="/dashboard/articles/" component={Articles} />
      <RouteAuthorized path="/dashboard/quizzes" component={Quizzes} />
      <RouteAdmin exact path="/dashboard/import-users" component={UsersImporting} />
      <RouteAdmin exact path="/dashboard/messages-management" component={MessagesManagement} />
      <RouteAuthorized path="/dashboard/users/preview/:userId" component={UsersPreview} />
      <Route exact path="/dashboard/rating/main-rating" component={MainRating} />
      <Route path="/dashboard/points-history" component={History} />
      <RouteAuthorized path="/dashboard/quizzes" component={Quizzes} />
      <Route path="/dashboard/special-tasks/" component={SpecialActions} />
      <RouteSuperUser exact path="/dashboard/hot-challenge" component={DHTHotChallenge} />
      <Route exact path="/dashboard/main-prize" component={MainPrize} />
      {/* <Route exact path="/dashboard/mikolaj-zakonczenie" component={MikolajFinish} />
      <Route exact path="/dashboard/zyczenia-2020" component={Christmas2020} /> */}
      {/* <Route exact path="/dashboard/simpla-finish" component={SimplaFinish} /> */}
      {/* <Route exact path="/dashboard/promotor-finish" component={PromotorFinish} /> */}
      {/* <Route exact path="/dashboard/keralis-finish" component={KeralisFinish} /> */}
      {/* <Route exact path="/dashboard/womens-day" component={WomensDay} /> */}
      {/* <Route exact path="/dashboard/cementowe-finish" component={CementoweFinish} /> */}
      <Route exact path="/dashboard/promotor-2-finish" component={PromotorSecondFinish} />
      <Route exact path="/dashboard/the-end" component={TheEnd} />
      {/* <Route exact path="/dashboard/nova-paka" component={NovaPaka} /> */}
      <Route exact path="/dashboard/competition-rules" component={CompetitionRules} />
    </Switch>
    <Footer />
    <MobileNav menuItems={nav} />
  </>
);

export default Dashboard;
