import React, { FC, useEffect } from 'react';
import ContactForm from '../../components/Form/ContactForm';
import useHeader from '../../hooks/useHeader';
import { Main, Container, Row } from '../../components/Layout';

const ContactPage = () => {
  useHeader('Formularz kontaktowy', '/', true);

  return (
    <Main noLeft>
      <Container>
        <Row buttonsContainer>
          <ContactForm />
        </Row>
      </Container>
    </Main>
  );
};

export default ContactPage;
