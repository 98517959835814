import React from 'react';
import clsx from 'clsx';
import Container from '../../Layout/Container';
import Row from '../../Layout/Row';
import Column from '../../Layout/Column';
import PageTitle from '../PageTitle';
import BackArrow from '../BackArrow';

import './PageTopHeader.scss';

interface Props {
  text: string;
  className?: string;
  backPath?: string;
}

const PageTopHeader = ({ text, backPath, className }: Props) => (
  <div className={clsx('page-top-header', { [`${className}`]: className })}>
    <Container>
      <Row>
        <Column>
          <PageTitle>
            <BackArrow path={backPath} />
            {text}
          </PageTitle>
        </Column>
      </Row>
    </Container>
  </div>
);

export default PageTopHeader;
