import uuidv4 from 'uuid/v4';
import { ApiErrorCode } from 'topdekarze-types';
import { SingleNotification } from '../types/notifications';

interface HideNotification {
  type: 'HIDE_NOTIFICATION';
  payload: string;
}

interface SuccessNotification {
  type: 'SUCCESS_NOTIFICATION';
  payload: string;
}

interface ErrorNotification {
  type: 'ERROR_NOTIFICATION';
  payload: ApiErrorCode;
}

type Action = HideNotification | SuccessNotification | ErrorNotification;

const initialState: SingleNotification[] = [];

const getError = (error: ApiErrorCode) => {
  switch (error) {
    case ApiErrorCode.InvalidLoginData:
      return 'Niepoprawne dane logowania.';
    case ApiErrorCode.FileIsTooLarge:
      return 'Plik za duży max 10MB';
    case ApiErrorCode.WrongLogoType:
      return 'Format pliku jest niepoprawny, użyj pliku w odpowiednim formacie (ai/eps/pdf)';
    case ApiErrorCode.ForebiddenFileFormat:
      return 'Niepoprawny format pliku';
    case ApiErrorCode.FormValidationError:
      return 'Błąd w formularzu';
    case ApiErrorCode.InvalidLang:
      return 'Niepoprawny język';
    case ApiErrorCode.InvalidOldPass:
      return 'Niepoprawne stare hasło';
    case ApiErrorCode.NoSuchObjectWithThisId:
      return 'Nie znaleziono żądanego zasobu';
    case ApiErrorCode.NotUniuqueParama:
      return 'Parametr nie jest unikalny';
    case ApiErrorCode.ObjectNotUniuque:
      return 'Obiekt nie jest unikalny';
    case ApiErrorCode.OtherError:
      return 'Wystąpił błąd';
    case ApiErrorCode.UpdateTokenExpired:
      return 'Token wygasł';
    case ApiErrorCode.UserAlreadySolvedTest:
      return 'Użytkownik rozwiązał już quiz';
    case ApiErrorCode.UserExists:
      return 'Użytkownik istnieje';
    case ApiErrorCode.UserIsBlockedFor15Mins:
      return 'Możliwość zalogowania została zablokowana na 15 minut';
    case ApiErrorCode.UserIsInactive:
      return 'Użytkownik jest nieaktywny';
    default:
      return 'Wystąpił błąd';
  }
};

const getErrorNotification = (text: string) => ({
  type: 'error',
  text,
  id: uuidv4(),
});
const getSuccessNotification = (text: string) => ({
  type: 'success',
  text,
  id: uuidv4(),
});

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SUCCESS_NOTIFICATION': {
      const notification = getSuccessNotification(action.payload);
      return [...state, notification];
    }
    case 'ERROR_NOTIFICATION': {
      const notification = getErrorNotification(getError(action.payload));
      return [...state, notification];
    }
    case 'HIDE_NOTIFICATION':
      return state.filter((notification: SingleNotification) => notification.id !== action.payload);
    default:
      return state;
  }
};
