import React, { useEffect } from 'react';
import useHeader from '../../hooks/useHeader';
import SectionTitle from '../../components/Common/SectionTitle';
import { Main, Container } from '../../components/Layout';
import { SectionSubtitle } from '../../components/Common';

const PrivacyPolicy = () => {
  useHeader('Polityka prywatności', '/', true, false);

  return (
    <Main noLeft>
      <Container>
        <strong>POLITYKA PRYWATNOŚCI STRONY INTERNETOWEJ WWW.TOPDEKARZE.PL</strong>
        <SectionTitle>POSTANOWIENIA OGÓLNE</SectionTitle>
        <p>
          Administratorem danych osobowych zbieranych za pośrednictwem strony internetowej www.topdekarze.pl i jej
          subdomen jest CREATON Polska Sp. z o.o., adres siedziby: Wspólna 6, 32-300 Olkusz, adres do doręczeń: ,
          wpisaną do rejestru przedsiębiorców pod numerem KRS: 0000114321, NIP: 8522097221, REGON: 811145245,
          posiadającą kapitał zakładowy w kwocie: , adres poczty elektronicznej:{' '}
          <a href="mailto:daneosobowe@creaton.pl">daneosobowe@creaton.pl</a> dalej „Administrator”.
        </p>
        <p>
          Dane osobowe zbierane przez Administratora za pośrednictwem strony internetowej są przetwarzane zgodnie z
          Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony
          osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych
          oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), zwane dalej RODO oraz ustawą o
          ochronie danych osobowych z dnia 10 maja 2018 r.
        </p>
        <SectionTitle>RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH, CEL I ZAKRES ZBIERANIA DANYCH</SectionTitle>
        <SectionSubtitle>CEL PRZETWARZANIA I PODSTAWA PRAWNA.</SectionSubtitle>
        <p>Administrator przetwarza dane osobowe za pośrednictwem strony www.topdekarze.pl w przypadku:</p>
        <ul className="list">
          <li>
            skorzystania przez użytkownika z formularza kontaktowego. Dane osobowe są przetwarzane na podstawie art. 6
            ust. 1 lit. f) RODO jako prawnie usprawiedliwiony interes Administratora.
          </li>
          <li>
            utworzenie konta uczestnika programu w serwisie TOP DEKARZE. Dane osobowe są przetwarzane na podstawie art.
            6 ust. 1 lit. f) RODO jako prawnie usprawiedliwiony interes Administratora.
          </li>
          <li>
            zapisania się przez użytkownika do Newslettera w celu przesyłania informacji handlowych drogą elektroniczną.
            Dane osobowe są przetwarzane po wyrażeniu odrębnej zgody, na podstawie art. 6 ust. 1 lit. a) RODO.
          </li>
          <li>
            kontaktu z użytkownikiem w celach związanych z jego aktywnością na platformie topdekarze.pl oraz w celach
            związanych z przekazaniem nagród w ramach konkursów.
          </li>
        </ul>
        <SectionSubtitle>RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH.</SectionSubtitle>
        <p>Administrator przetwarza następujące kategorie danych osobowych użytkownika:</p>
        <ul className="list">
          <li>Imię i nazwisko,</li>
          <li>Adres (prowadzenia działalności),</li>
          <li>Adres e-mail,</li>
          <li>Numer telefonu,</li>
          <li>Zdjęcia zrealizowanych dachów,</li>
          <li>Zdjęcie uczestnika programu (dobrowolne),</li>
        </ul>
        <SectionSubtitle>OKRES ARCHIWIZACJI DANYCH OSOBOWYCH.</SectionSubtitle>{' '}
        <p>Dane osobowe użytkowników przechowywane są przez Administratora:</p>
        <ul>
          <li>
            tak długo, aż zgoda nie zostanie odwołana, a po odwołaniu zgody przez okres czasu odpowiadający okresowi
            przedawnienia roszczeń jakie może podnosić Administrator i jakie mogą być podnoszone wobec niego. Jeżeli
            przepis szczególny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a dla roszczeń o świadczenia
            okresowe oraz roszczeń związanych z prowadzeniem działalności gospodarczej - trzy lata.
          </li>
          <li>
            Podczas korzystania ze strony internetowej mogą być pobierane dodatkowe informacje, w szczególności: adres
            IP przypisany do komputera użytkownika lub zewnętrzny adres IP dostawcy Internetu, nazwa domeny, rodzaj
            przeglądarki, czas dostępu, typ systemu operacyjnego.
          </li>
          <li>
            Od użytkowników mogą być także gromadzone dane nawigacyjne, w tym informacje o linkach i odnośnikach, w
            które zdecydują się kliknąć lub innych czynnościach, podejmowanych na stronie internetowej. Podstawą prawną
            tego rodzaju czynności jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO),
            polegający na ułatwieniu korzystania z usług świadczonych drogą elektroniczną oraz na poprawie
            funkcjonalności tych usług.
          </li>
        </ul>
        <p>
          Podanie danych osobowych przez użytkownika jest dobrowolne. Jednak brak ich podania skutkuje brakiem
          możliwości wzięcia udziału w programie TOP Dekarze.
        </p>
        <p>
          Dane osobowe będą przetwarzane także w sposób zautomatyzowany w formie profilowania, o ile użytkownik wyrazi
          na to zgodę na podstawie art. 6 ust. 1 lit. a) RODO. Konsekwencją profilowania będzie przypisanie danej osobie
          profilu w celu podejmowania dotyczących jej decyzji bądź analizy lub przewidywania jej preferencji, zachowań i
          postaw.
        </p>
        <p>
          Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których dane dotyczą, a w
          szczególności zapewnia, że zbierane przez niego dane są:
        </p>
        <ul className="list">
          <li>przetwarzane zgodnie z prawem,</li>
          <li>
            zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi
            celami,
          </li>
          <li>
            merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane oraz przechowywane w
            postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia
            celu przetwarzania.
          </li>
        </ul>
        <SectionTitle>UDOSTĘPNIENIE DANYCH OSOBOWYCH</SectionTitle>
        <p>
          Dane osobowe użytkowników przekazywane są dostawcom usług, z których korzysta Administrator przy prowadzeniu
          strony internetowej. Dostawcy usług, którym przekazywane są dane osobowe, w zależności od uzgodnień umownych i
          okoliczności, albo podlegają poleceniom Administratora co do celów i sposobów przetwarzania tych danych
          (podmioty przetwarzające) albo samodzielnie określają cele i sposoby ich przetwarzania (administratorzy).
        </p>
        <p>
          W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe innym odbiorcom, jeśli będzie to
          niezbędne do wykonania zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na Administratorze.
          Dotyczy to takich grup odbiorców:
        </p>
        <ul>
          <li>firma hostingowa na zasadzie powierzenia</li>
          <li>kurierzy</li>
          <li>operatorzy pocztowi</li>
          <li>
            upoważnieni pracownicy i współpracownicy, którzy korzystają z danych w celu realizacji celu działania strony
          </li>
          <li>firmy, świadczące usługi marketingu na rzecz Administratora</li>
        </ul>
        <p>
          Dane osobowe użytkowników są przechowywane wyłącznie na terenie Europejskiego Obszaru Gospodarczego (EOG).
        </p>
        <SectionTitle>WYBRANE METODY OCHRONY DANYCH STOSOWANE PRZEZ OPERATORA</SectionTitle>
        <p>
          Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). Dzięki
          temu dane osobowe i dane logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze użytkownika i
          mogą być odczytane jedynie na docelowym serwerze.
        </p>
        <p>
          Hasła użytkowników są przechowywane w postaci hashowanej. Funkcja hashująca działa jednokierunkowo - nie jest
          możliwe odwrócenie jej działania, co stanowi obecnie współczesny standard w zakresie przechowywania haseł
          użytkowników.
        </p>
        <p>Operator okresowo zmienia swoje hasła administracyjne.</p>
        <p>W celu ochrony danych Operator regularnie wykonuje kopie bezpieczeństwa.</p>
        <p>
          Istotnym elementem ochrony danych jest regularna aktualizacja wszelkiego oprogramowania, wykorzystywanego
          przez Operatora do przetwarzania danych osobowych, co w szczególności oznacza regularne aktualizacje
          komponentów programistycznych.
        </p>
        <SectionTitle>PRAWO KONTROLI, DOSTĘPU DO TREŚCI WŁASNYCH DANYCH ORAZ ICH POPRAWIANIA</SectionTitle>
        <p>
          Osoba, której dane dotyczą, ma prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania,
          usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do
          cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na
          podstawie zgody przed jej cofnięciem.
        </p>
        <p>Podstawy prawne żądania użytkownika:</p>
        <ul className="list">
          <li>Dostęp do danych – art. 15 RODO</li>
          <li>Sprostowanie danych – art. 16 RODO.</li>
          <li>Usunięcie danych (tzw. prawo do bycia zapomnianym) – art. 17 RODO.</li>
          <li>Ograniczenie przetwarzania – art. 18 RODO.</li>
          <li>Przeniesienie danych – art. 20 RODO.</li>
          <li>Sprzeciw – art. 21 RODO</li>
          <li>Cofnięcie zgody – art. 7 ust. 3 RODO.</li>
        </ul>
        <p>
          W celu realizacji uprawnień, o których mowa w pkt 2 można wysłać stosowną wiadomość e-mail na adres:{' '}
          <a href="mailto:daneosobowe@creaton.pl">daneosobowe@creaton.pl</a>.
        </p>
        <p>
          W sytuacji wystąpienia przez użytkownika z uprawnieniem wynikającym z powyższych praw, Administrator spełnia
          żądanie albo odmawia jego spełnienia niezwłocznie, nie później jednak niż w ciągu miesiąca po jego otrzymaniu.
          Jeżeli jednak - z uwagi na skomplikowany charakter żądania lub liczbę żądań – Administrator nie będzie mógł
          spełnić żądania w ciągu miesiąca, spełni je w ciągu kolejnych dwóch miesięcy informując użytkownika uprzednio
          w terminie miesiąca od otrzymania żądania - o zamierzonym przedłużeniu terminu oraz jego przyczynach.
        </p>
        <p>
          W przypadku stwierdzenia, że przetwarzanie danych osobowych narusza przepisy RODO, osoba, której dane dotyczą,
          ma prawo wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.
        </p>
        <SectionTitle>PLIKI "COOKIES"</SectionTitle>
        <p>Strona Administratora używa plików „cookies”.</p>
        <p>
          Instalacja plików „cookies” jest konieczna do prawidłowego świadczenia usług na stronie internetowej. W
          plikach „cookies" znajdują się informacje niezbędne do prawidłowego funkcjonowania strony, a także dają one
          także możliwość opracowywania ogólnych statystyk odwiedzin strony internetowej.
        </p>
        <p>W ramach strony stosowane są rodzaje plików "cookies": sesyjne, stałe.</p>
        <p>
          „Cookies” „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym użytkownika do czasu
          wylogowania (opuszczenia strony).
        </p>
        <p>
          „Stałe” pliki „cookies” przechowywane są w urządzeniu końcowym użytkownika przez czas określony w parametrach
          plików „cookies” lub do czasu ich usunięcia przez użytkownika.
        </p>
        <p>
          Administrator wykorzystuje własne pliki cookies w celu lepszego poznania sposobu interakcji użytkownika w
          zakresie zawartości strony. Pliki gromadzą informacje o sposobie korzystania ze strony internetowej przez
          użytkownika, typie strony, z jakiej użytkownik został przekierowany oraz liczbie odwiedzin i czasie wizyty
          użytkownika na stronie internetowej. Informacje te nie rejestrują konkretnych danych osobowych użytkownika,
          lecz służą do opracowania statystyk korzystania ze strony.
        </p>
        <p>
          Użytkownik ma prawo zadecydowania w zakresie dostępu plików „cookies” do swojego komputera poprzez ich
          uprzedni wybór w oknie swojej przeglądarki. Szczegółowe informacje o możliwości i sposobach obsługi plików
          „cookies” dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).
        </p>
        <SectionTitle>LOGI ADMINISTRACYJNE</SectionTitle>
        <p>
          Informacje zachowaniu użytkowników w serwisie mogą podlegać logowaniu. Dane te są wykorzystywane w celu
          administrowania serwisem.
        </p>
        <SectionTitle>POSTANOWIENIA KOŃCOWE</SectionTitle>{' '}
        <p>
          Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych osobowych
          odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane przed ich
          udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem
          obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.
        </p>
        <p>
          Administrator udostępnia odpowiednie środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu przez osoby
          nieuprawnione, danych osobowych przesyłanych drogą elektroniczną.
        </p>
        <p>
          W sprawach nieuregulowanych niniejszą Polityką prywatności stosuje się odpowiednio przepisy RODO oraz inne
          właściwe przepisy prawa polskiego.
        </p>
      </Container>
    </Main>
  );
};

export default PrivacyPolicy;
