import React from 'react';
import { EventTableRes } from 'topdekarze-types';
import { eventTableSchema } from 'topdekarze-tables';
import { useDispatch } from 'react-redux';
import { Main, Container, ButtonsContainer } from '../../../../components/Layout';
import ApiTable from '../../../../components/Common/ApiTable';
import { InteractiveTableSchema } from '../../../../utils/table-schema-utils';
import TableWrapper from '../../../../components/Common/TableWrapper';
import useHeader from '../../../../hooks/useHeader';
import { showModal, hideModal } from '../../../../actions/modal';
import Confirmation from '../../../../components/Modals/Confirmation';
import ApiService from '../../../../services/api-service';
import { refreshApiTable } from '../../../../actions/api-table';
import { successNotification } from '../../../../actions/notifications';
import { history } from '../../../../App';
import { Button } from '../../../../components/Common';

const Manage = (): JSX.Element => {
  useHeader('Lista zadań specjalnych', '/dashboard/');
  const dispatch = useDispatch();

  const deleteSpecialTask = async (specialTask: EventTableRes) => {
    await ApiService.callFetch(
      'DELETE',
      `event/delete/${specialTask.id}`,
      () => {
        dispatch(refreshApiTable());
        dispatch(successNotification('application.removedSpecialTaskProperly'));
        dispatch(hideModal());
      },
      null,
    );
  };

  const confirmDelete = (specialTask: EventTableRes) => {
    dispatch(
      showModal(
        <Confirmation
          text={`Czy na pewno chcesz usunąć zadanie ${specialTask.title}?`}
          confirmCallback={() => {
            deleteSpecialTask(specialTask);
          }}
        />,
      ),
    );
  };

  const goToSpecialTask = (specialTask: EventTableRes) => {
    history.push(`/dashboard/special-tasks/${specialTask.id}`);
  };

  const editSpecialTask = (specialTask: EventTableRes) => {
    history.push(`/dashboard/special-tasks/edit/${specialTask.id}`);
  };

  const scheme = new InteractiveTableSchema(eventTableSchema).removeFields('id').addFieldAtTheEnd({
    name: 'Akcje',
    field: 'actions',
    buttons: [
      { name: 'Usuń', type: 'danger', click: confirmDelete },
      { name: 'Edytuj', type: 'default', click: editSpecialTask },
      { name: 'Zobacz', type: 'primary', click: goToSpecialTask },
    ],
  });

  return (
    <Main>
      <Container>
        <ButtonsContainer>
          <Button small to="/dashboard/special-tasks/add" text="Nowe zadanie specjalne" />
        </ButtonsContainer>
      </Container>
      <TableWrapper>
        <ApiTable scheme={scheme} apiEndpointSubUrl="event/table/all" />
      </TableWrapper>
    </Main>
  );
};

export default Manage;
