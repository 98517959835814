import React, { FC } from 'react';
import clsx from 'clsx';
import { DashboardNav } from '../../../types/navs';
import SocialItem from './SocialItem';
import './SocialsPanel.scss';

interface Props {
  column?: boolean;
}

const socials: DashboardNav[] = [
  {
    text: 'Facebook',
    path: 'https://www.facebook.com/CreatonPolska/',
    iconName: 'facebook',
    tooltip: 'Facebook',
  },
  {
    text: 'Instagram',
    path: 'https://www.instagram.com/creaton_polska/',
    iconName: 'instagram',
    tooltip: 'Instagram',
  },
];

const SocialsPanel: FC<Props> = ({ column }) => (
  <ul className={clsx('socials', { column })}>
    {socials.map((link) => {
      const { iconName, path } = link;
      return <SocialItem key={iconName} iconName={iconName} path={path} />;
    })}
  </ul>
);

export default SocialsPanel;
