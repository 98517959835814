// @ts-nocheck
import React, { useRef, FC } from 'react';
import { FormFieldType } from 'itf_formbuilder';
import { ItfApiForm } from 'itf_formbuilder_react';
import Select from 'react-select';
import CKEditor from '@ckeditor/ckeditor5-react';
import CKEditorEditorBuild from 'topdekarze-ckeditor/dist/ckeditor';
import Creatable from 'react-select/creatable';
import { useDispatch } from 'react-redux';
import { successNotification } from '../../../../actions/notifications';
import FormContainer from '../../../Layout/FormContainer';
import { Spinner, Input, Button } from '../../../Common';
import { ButtonsContainer } from '../../../Layout';
import ApiService from '../../../../services/api-service';
import { __ } from '../../../../helpers/i18n';
import { formOnTranslateString } from '../../../../utils/trans-form';
import { showModal, hideModal } from '../../../../actions/modal';
import Confirmation from '../../../Modals/Confirmation';

import './SendMailForm.scss';

const SendMailInfoForm: FC = () => {
  const ref = useRef<ItfApiForm>(null);
  const dispatch = useDispatch();

  return (
    <FormContainer grid className="send-mail-form">
      <ItfApiForm
        ref={ref}
        formId="sendMailForm"
        schemaMayBeDynamic
        useFormDataInsteadOfPlainObject
        schemaCacheEnabled={false}
        loadingInfoAboveContent
        loadingInfo={<Spinner overlay />}
        schemaFetcher={() => ApiService.loadForm('admin/form/send-mail-info')}
        onTranslateString={formOnTranslateString}
        thirdPartyComponents={{
          ReactSelect: Select,
          ReactCreatableSelect: Creatable,
          CKEditor,
          CKEditorEditorBuild,
        }}
        sendFetcher={(formId: string, values: AuthLoginRequest) => ApiService.sendForm('admin/send-mail-info', values)}
        submitButton={
          <ButtonsContainer>
            <Button
              secondary
              type="button"
              text={__('application.send')}
              click={() =>
                dispatch(
                  showModal(
                    <Confirmation
                      mailing
                      text="Czy na pewno chcesz wysłać wiadomość mailową?"
                      confirmCallback={async () => {
                        dispatch(hideModal());
                        await ref.current.api.send();
                      }}
                    />,
                  ),
                )
              }
            />
          </ButtonsContainer>
        }
        onSavedSuccessfully={() => {
          dispatch(successNotification(__('application.sent')));
        }}
        onRenderFullField={(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur) => {
          switch (field.type) {
            case FormFieldType.Text:
              return (
                <Input
                  type={field.options.type || field.type}
                  placeholder={field.placeholder}
                  label={field.title}
                  input={{
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    errors,
                    value: currentValue,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
                    onFocus,
                    onBlur,
                  }}
                />
              );
            default:
              return null;
          }
        }}
      />
    </FormContainer>
  );
};

export default SendMailInfoForm;
