import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UserRole, AcceptStatus, TransactionTableRes } from 'topdekarze-types';
import { FieldType, transactionTableSchema } from 'topdekarze-tables';
import { RootState } from '../../../../reducers';
import useHeader from '../../../../hooks/useHeader';
import { Main, Container, ButtonsContainer } from '../../../../components/Layout';
import { Button } from '../../../../components/Common';
import { history } from '../../../../App';
import ApiTable from '../../../../components/Common/ApiTable';
import { InteractiveTableSchema } from '../../../../utils/table-schema-utils';
import TableWrapper from '../../../../components/Common/TableWrapper';
import useEventList from '../../../../hooks/events/useEventList';
import { showModal } from '../../../../actions/modal';
import ModalContent from '../../../../components/Common/Modal/ModalContent';
import ModalHeader from '../../../../components/Common/Modal/ModalHeader';
import ModalTitle from '../../../../components/Common/Modal/ModalTitle';

const AllTransactions: FC = () => {
  useHeader('Realizacje', '/dashboard');
  const user = useSelector((state: RootState) => state.user.details);
  const { events } = useEventList();
  const dispatch = useDispatch();

  const showModalWithInfo = (text: string) => {
    dispatch(
      showModal(
        <ModalContent>
          <ModalHeader>
            <ModalTitle title="Transakcja została odrzucona z powodu:" />
          </ModalHeader>
          <p>{text}</p>
        </ModalContent>,
      ),
    );
  };

  const schema = new InteractiveTableSchema(transactionTableSchema)
    .removeFields('id')
    .removeFields('rejectDescription')
    .addFieldAtTheEnd({
      name: 'Akcje',
      field: 'actions',
      type: FieldType.Custom,
      customRender: (value, field, row: any) => {
        if (row.acceptStatus === AcceptStatus.Unaccepted) {
          return (
            <Button
              primary
              small
              text="Weryfikuj"
              click={(id: string) => history.push(`/dashboard/transactions/list/${row.id}`)}
            />
          );
        }
        if (row.acceptStatus === AcceptStatus.Rejected && row.rejectDescription) {
          return <Button small text="Powód odrzucenia" click={() => showModalWithInfo(row.rejectDescription)} />;
        }
      },
      mobile: true,
    });
  return (
    <Main>
      {user?.role === UserRole.Admin && events?.find((event) => event.id === '0a7c5ef2-c408-4d11-9c9d-d1cac407d094') && (
        <Container>
          <ButtonsContainer>
            <Button small to="/dashboard/transactions/add-dht-h-c" text="Dodaj zgłoszenie DHT Hot Challenge" />
          </ButtonsContainer>
        </Container>
      )}
      <TableWrapper>
        <ApiTable scheme={schema} apiEndpointSubUrl="transaction/my-list" />
      </TableWrapper>
    </Main>
  );
};

export default AllTransactions;
