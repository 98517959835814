import { useEffect, useState } from 'react';
import { ArticleRes, UserRole } from 'topdekarze-types';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import ApiService from '../../services/api-service';
import useHeader from '../useHeader';
import { RootState } from '../../reducers';
import { __ } from '../../helpers/i18n';

export enum Mode {
  Preview = 'preview',
  Edit = 'edit',
}

interface UseArticlePreview {
  article: ArticleRes | undefined;
  mode: Mode;
  isLoading: boolean;
  changeMode: (currentMode: Mode) => void;
  getPhoto: () => string;
  isAdmin: boolean | undefined;
}

const useArticlePreview = (): UseArticlePreview => {
  useHeader(__('application.article'), '/dashboard/articles');
  const { articleId } = useParams<{ articleId?: string | undefined }>();
  const [article, setArticle] = useState<ArticleRes>();
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState(Mode.Preview);
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>();
  const userRole = useSelector((state: RootState) => state.user.details?.role);

  const getArticle = async () => {
    if (mode === Mode.Preview) {
      await ApiService.callFetch(
        'GET',
        `article/${articleId}`,
        (data: ArticleRes) => {
          setArticle(data);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
      );
    }
  };

  const getPhoto = () => `${ApiService.url}article/image/${articleId}`;

  const changeMode = (currentMode: Mode) => {
    if (currentMode === Mode.Preview) {
      setMode(Mode.Edit);
    } else {
      setMode(Mode.Preview);
    }
  };

  const isAdminFn = (): void => {
    if (UserRole.Admin === userRole) {
      setIsAdmin(true);
    } else setIsAdmin(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getArticle();
    };
    fetchData();
    isAdminFn();
  }, []);

  return { article, isLoading, getPhoto, changeMode, mode, isAdmin };
};

export default useArticlePreview;
