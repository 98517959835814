import React, { FC } from 'react';
import { Container, Main } from '../../../../components/Layout';
import CreateQuizForm from './CreateQuizForm';

const CreateQuiz: FC = () => (
  <Main>
    <Container>
      <CreateQuizForm />
    </Container>
  </Main>
);

export default CreateQuiz;
