import React from 'react';
import clsx from 'clsx';
import { UserRole } from 'topdekarze-types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import useQuiz from '../../../../hooks/quizzes/useQuiz';
import { Button } from '../../../../components/Common';
import EntryText from '../../../../components/Common/EntryText';
import Card from '../../../../components/Common/Card';
import useHeader from '../../../../hooks/useHeader';
import { Main, Container, ButtonsContainer, Row, Column } from '../../../../components/Layout';
import { history } from '../../../../App';

import './Quiz.scss';
import ApiService from '../../../../services/api-service';

const Quiz = () => {
  const {
    quiz,
    quizId,
    quizStat,
    checkAnswer,
    isDisabled,
    startQuiz,
    endQuiz,
    selected,
    questionResult,
    runQuiz,
    isStarted,
    points,
    quizInfo,
    action,
  } = useQuiz();

  const user = useSelector((state: RootState) => state.user.details);
  useHeader('Quizy', '/dashboard/quizzes');

  return (
    <Main>
      <Container>
        {!isStarted ? (
          <Row className="quiz-start-row">
            {quizInfo?.description && <EntryText>{quizInfo.description}</EntryText>}
            {quizInfo && (
              <Card
                key={quizInfo.id}
                className=" quizzes-list quiz-start-card"
                title={quizInfo.title}
                imgUrl={`quiz/get-photo/${quizId}`}
                isFirst={false}
                completed={false}
                points={`${quizInfo.maxPoints} PKT`}
                center
              />
            )}
            <Column center>
              {!points && points !== 0 && (
                <ButtonsContainer>
                  <Button
                    mainColor
                    click={() => (quizStat !== 'finished' ? runQuiz() : endQuiz())}
                    text={action || ''}
                  />
                </ButtonsContainer>
              )}
              {(points || points === 0) && (
                <div className="quiz-result">
                  Liczba zdobytych punktów: {points}.{points > 0 && ' Gratulacje!'}
                </div>
              )}
            </Column>
            {quizInfo?.articleId && (
              <Column center>
                <ButtonsContainer>
                  <Button
                    secondary
                    click={() => history.push(`/dashboard/articles/view/${quizInfo.articleId}`)}
                    text="Baza wiedzy"
                  />
                </ButtonsContainer>
              </Column>
            )}
          </Row>
        ) : (
          <>
            <Row className="quiz-buttons-wrapper">
              {quiz?.question?.questionPictureFn && (
                <Column>
                  <img
                    className="question-photo"
                    src={`${ApiService.url}quiz/get-question-photo/${quiz.question.id}`}
                    alt=""
                  />
                </Column>
              )}
              <Column xs={12}>{quiz?.question && <h4 className="quiz-question">{quiz.question.question}</h4>}</Column>
              {quiz &&
                quiz?.answer &&
                quiz?.answer?.length > 0 &&
                quiz?.answer.map((answer: any) => (
                  <Column md={3} className="quiz-buttons">
                    <Button
                      className={clsx('quiz-button', {
                        'wrong-answer': answer.id === selected && selected !== questionResult,
                        'good-answer': answer.id === questionResult,
                      })}
                      disabled={isDisabled}
                      text={answer.content}
                      click={() => {
                        checkAnswer({
                          answerId: answer.id,
                          questionId: quiz.question.id,
                          quizId,
                        });
                      }}
                    />
                  </Column>
                ))}
            </Row>
            <Row>
              <Column>
                {quiz && isDisabled && <Button mlA primary click={() => startQuiz()} text="Dalej" />}
                {(quiz === null || quiz?.answer === null) && (
                  <div>
                    <EntryText>Odpowiedziałeś na wszystkie pytania do quizu "{quizInfo.title}"!</EntryText>
                    {!points && points !== 0 && user?.role === UserRole.User && (
                      <ButtonsContainer>
                        <Button primary click={() => endQuiz()} text="Zobacz wynik" />
                      </ButtonsContainer>
                    )}
                    {(points || points === 0) && (
                      <div className="quiz-result">
                        Liczba zdobytych punktów: {points}.{points > 0 && ' Gratulacje!'}
                      </div>
                    )}
                  </div>
                )}
              </Column>
            </Row>
          </>
        )}
      </Container>
    </Main>
  );
};

export default Quiz;
