import { useEffect, useState } from 'react';
import { ArticleRes } from 'topdekarze-types';
import ApiService from '../../services/api-service';

interface UseListArticle {
  articles: ArticleRes[];
  isLoading: boolean;
  isFirst: (index: number) => boolean;
}

const useListArticle = (): UseListArticle => {
  const [articles, setArticles] = useState<ArticleRes[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const isFirst = (index: number) => index === 0;

  const getArticles = async () => {
    await ApiService.callFetch(
      'GET',
      'article/list/all',
      (data: ArticleRes[]) => {
        setIsLoading(false);
        setArticles(data);
      },
      () => setIsLoading(false),
    );
  };

  useEffect(() => {
    getArticles();
  }, []);

  return { articles, isLoading, isFirst };
};

export default useListArticle;
