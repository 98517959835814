import React, { FC, useState } from 'react';
import { Main } from '../../components/Layout';
import Container from '../../components/Layout/Container';
import WelcomeText from '../../components/Common/WelcomeText';
import { __ } from '../../helpers/i18n';
import ImportedLoginForm from '../../components/Form/ImportedLoginForm';
import useHeader from '../../hooks/useHeader';

const WelcomePage: FC = () => {
  useHeader(`${__('application.setPassword')}`, '/', true);
  const [userName] = useState('Nowy Użytkowniku');

  return (
    <Main noLeft alignEnd>
      <Container className="welcome-page-wrapper">
        <WelcomeText>
          {__('application.welcome')}
          {` ${userName}`}
        </WelcomeText>
        <ImportedLoginForm />
      </Container>
    </Main>
  );
};

export default WelcomePage;
