import React from 'react';
import { DashboardNav } from '../../../types/navs';
import { Container, Row } from '../../Layout';
import NavItem from '../NavItem';

import './MobileNav.scss';

interface Props {
  menuItems: DashboardNav[];
}

const MobileNav = ({ menuItems }: Props) => (
  <Container className="mobile-nav" fluid>
    <Row as="ul">
      {menuItems.map((item) => {
        const { iconName, path } = item;
        return (
          <NavItem key={iconName} iconName={iconName} path={path} className={path === 'user' ? 'last-item' : ''} />
        );
      })}
    </Row>
  </Container>
);

export default MobileNav;
