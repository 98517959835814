import React, { FC } from 'react';
import { FieldType, usersTableSchema } from 'topdekarze-tables';
import { useDispatch } from 'react-redux';
import { UserTableRes } from 'topdekarze-types';
import { ButtonsContainer, Main } from '../../../components/Layout';
import { InteractiveTableSchema } from '../../../utils/table-schema-utils';
import { __ } from '../../../helpers/i18n';
import { showModal, hideModal } from '../../../actions/modal';
import useHeader from '../../../hooks/useHeader';
import { ApiTable, Button, TableWrapper } from '../../../components/Common';
import { history } from '../../../App';
import ApiService from '../../../services/api-service';
import { successNotification } from '../../../actions/notifications';
import Confirmation from '../../../components/Modals/Confirmation';

const MyUsers: FC = () => {
  useHeader(__('application.myUsers'), '/dashboard');
  const dispatch = useDispatch();

  const sendReminder = async (row: UserTableRes) => {
    await ApiService.callFetch(
      'POST',
      `user/send-reminder/${row.id}`,
      () => {
        dispatch(successNotification(__('application.registrationReminderSentProperly')));
      },
      null,
    );
  };

  const handleReminder = (row: UserTableRes) => {
    dispatch(
      showModal(
        <Confirmation
          text={`Czy na pewno chcesz wysłać przypomnienie o rejestracji użytkownikowi: ${row.firstName} ${row.lastName}?`}
          confirmCallback={() => {
            dispatch(hideModal());
            sendReminder(row);
          }}
        />,
      ),
    );
  };

  const renderOtherActionsButtons = (row: UserTableRes) => {
    return (
      <ButtonsContainer>
        <Button text="Podgląd" small success click={() => history.push(`/dashboard/users/preview/${row.id}`)} />
        {!row.rulesAccepted && <Button text="Przypomnij" small danger click={() => handleReminder(row)} />}
      </ButtonsContainer>
    );
  };

  const scheme = new InteractiveTableSchema(usersTableSchema)
    .addFieldAtTheEnd({
      name: 'Akcje',
      field: 'actions',
      type: FieldType.Custom,
      customRender: (value, field, row: UserTableRes) => renderOtherActionsButtons(row),
      mobile: true,
    })
    .removeFields('id');

  return (
    <Main className="main-users">
      <TableWrapper>
        <ApiTable scheme={scheme} apiEndpointSubUrl="superuser/find-all-my-users" />
      </TableWrapper>
    </Main>
  );
};

export default MyUsers;
