import React, { FC } from 'react';
import useHeader from '../../../hooks/useHeader';
import { Main, Container } from '../../../components/Layout';
import { SectionTitle } from '../../../components/Common';

import './MainPrize.scss';

const MainPrize: FC = () => {
  useHeader('Nagroda główna', '/');

  return (
    <Main className="main-prize">
      <Container>
        <img src="/assets/images/" className="center" alt="" />
        <SectionTitle highlight>NAGRODA GŁÓWNA</SectionTitle>
        <img src="/assets/images/competition/2.jpg" className="center" alt="" />
        <p>
          W tym sezonie TopDekarz nakręca jeszcze bardziej. Wybieramy grupę <strong>30 najlepszych Top Dekarzy</strong>,
          na których czekają iście dekarskie nagrody. Warto się starać i być aktywnym. 10 pierwszych Dekarzy z rankingu
          będzie mogło w ramach wygranego przez siebie budżetu zaprojektować „PAKĘ” marzeń swojej brygadówki, busa, vana
          lub pick-upa. Zwycięzcy Dekarze wraz z pomocą naszych specjalistów będą wybierać elementy i moduły wykonania.
          Po przeniesieniu tego na projekt bierzemy się od razu za jego wykonanie. Wszystkie elementy będą realizowane z
          Moduł System - jednym z wiodących producentów samochodowych systemów regałowych na świecie i specjalistę w
          zakresie zabudowy samochodów dostawczych.
        </p>
        <img src="/assets/images/main-prize/2.jpg" alt="" />
        <p>
          Po zakończeniu II edycji programu ze zwycięskimi Dekarzami skontaktują się nasi specjaliści, aby uzgodnić
          wszystkie szczegóły dotyczące preferencji zabudowy w ramach zwycięskiego budżetu.
        </p>
        <img src="/assets/images/main-prize/5.jpg" className="center" alt="" />
        <p>
          To jednak jeszcze nie koniec. Cenimy sobie fachowość i eksperckość w Waszym wykonaniu więc pozostałą ’20
          nagradzamy dedykowanymi zestawami renomowanej marki DeWalt.
        </p>
        <img src="/assets/images/main-prize/6.jpg" alt="" />
        <img src="/assets/images/main-prize/7.jpg" className="center" alt="" />
        <p>
          Szlifierka kątowa 18V DCG405, Młotowiertarka SDS-Plus 18V DCH273, Wiertarko, Wkrętarka udarowa 18V DCD796,
          Zakrętarka udarowa 18V DCF887, Akumulator Li-ion 18V 5 Ah DCB184, Ładowarka Li-ion DCB115, Skrzynia
          narzędziowa ToughSystem DS150, Skrzynia narzędziowa ToughSystem DS300
        </p>
        <img src="/assets/images/main-prize/8.jpg" alt="" />
        <img src="/assets/images/main-prize/9.jpg" className="center" alt="" />
        <p>
          Wkrętarka udarowa 70Nm Dewalt DCD796NT, Wkrętarka udarowa 70Nm Dewalt DCD796NT, 2 akumulatory o pojemności 5Ah
          każdy, Ładowarka, 2 walizki
        </p>
        <p className="big">
          W tym sezonie nagrody wpisują się w dekarski kunszt i tym samym nakręcą Was do aktywnego działania. Jesteście
          gotowi do tego, aby być w gronie 30 TopDekarzy? My w to nie wątpimy - więc do dzieła. Zmagania w II edycji
          TopDekarz Creaton Polska czas rozpocząć!!!
        </p>
        <p className="bigger">Życzymy powodzenia i do zobaczenia na mecie.</p>
      </Container>
    </Main>
  );
};

export default MainPrize;
