import React from 'react';
import { Container } from '../../../../components/Layout';
import DashboardBottomSection from '../DashboardBottomSection';
import UserDetails from '../../../../components/Common/UserDetails';
import EditOption from '../../../../components/Common/EditOption';

import './EntrySection.scss';

interface Props {
  noStats?: boolean;
}

const EntrySection = ({ noStats }: Props) => (
  <section className="entry-section">
    <Container>
      <UserDetails className="user-details-dashboard" hideEmail showAvatar />
      {!noStats && <DashboardBottomSection />}
      <EditOption className="edit-option" />
    </Container>
  </section>
);

export default EntrySection;
