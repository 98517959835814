import React, { useState, useEffect } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import { Container } from '../../../../components/Layout';
import Icon from '../../../../components/Common/Icon';
import PageTitle from '../../../../components/Common/PageTitle';
import ApiService from '../../../../services/api-service';
import useActionsGallery from '../../../../hooks/useActionsGallery';

import './SpecialActionsGallery.scss';

const SpecialActionsGallery = () => {
  const { actionsWithGallery } = useActionsGallery();
  const [isMobile, setIsMobile] = useState<undefined | boolean>();

  const checkWidth = () => {
    const match = window.matchMedia('(max-width: 768px)');
    if (match.matches) {
      setIsMobile(true);
    }
  };
  useEffect(() => {
    checkWidth();
  }, []);
  if (
    !actionsWithGallery ||
    actionsWithGallery.length === 0 ||
    actionsWithGallery.filter((action: any) => action?.eventPhotoIds.length !== 0).length === 0
  ) {
    return null;
  }
  return (
    <section className="dashboard-gallery">
      <Container>
        <PageTitle>Galeria akcji czasowych</PageTitle>
      </Container>
      {actionsWithGallery.map((action: any) => {
        if (action?.eventPhotoIds.length > 0) {
          return (
            <>
              <Container>
                <h2 className="gallery-subtitle">{action?.title}</h2>
              </Container>
              <Container className="actions-banner transaction-carousel carousel gallery-carousel">
                <Carousel
                  arrows
                  arrowLeft={<Icon className="carousel-icon" icon="left-arrow" />}
                  arrowRight={<Icon className="carousel-icon" icon="right-arrow" />}
                  addArrowClickHandler
                  draggable
                  slidesPerPage={isMobile ? 1 : 4}
                >
                  {action?.eventPhotoIds.map((photo: any) => (
                    <div className="carousel-item">
                      <img src={`${ApiService.url}transaction/get-photos/${photo}`} alt="" />
                    </div>
                  ))}
                </Carousel>
              </Container>
            </>
          );
        }
      })}
    </section>
  );
};

export default SpecialActionsGallery;
