import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { ContactUsReq } from 'topdekarze-types';
import { FormContainer } from '../../Layout';
import { Spinner, Button } from '../../Common';
import ApiService from '../../../services/api-service';
import { __ } from '../../../helpers/i18n';
import { formOnTranslateString } from '../../../utils/trans-form';
import { successNotification } from '../../../actions/notifications';
import { history } from '../../../App';

import './ContactForm.scss';

const ContactForm: FC = () => {
  const dispatch = useDispatch();
  return (
    <FormContainer className="contact-form">
      <ItfApiForm
        formId="contactForm"
        schemaMayBeDynamic
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        schemaFetcher={() => ApiService.loadForm('user/form/contact')}
        sendFetcher={(formId: string, values: ContactUsReq) => ApiService.sendForm('user/contact-us', values)}
        submitButton={<Button primary type="submit" text={__('application.send')} />}
        onTranslateString={formOnTranslateString}
        onSavedSuccessfully={() => {
          dispatch(successNotification(__('application.contactFormSent')));
          history.push('/');
        }}
      />
    </FormContainer>
  );
};

export default ContactForm;
