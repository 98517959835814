import React from 'react';
import clsx from 'clsx';

import './FormContainer.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  grid?: boolean;
}

const FormContainer = ({ children, className, grid }: Props): JSX.Element => (
  <div className={clsx('form-container', className, { grid })}>{children}</div>
);

export default FormContainer;
