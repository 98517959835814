import React, { FC } from 'react';
import Image from '../Image';

import './DashboardBackground.scss';

interface Props {
  backgroundImage?: string;
}

const DashboardBackground: FC<Props> = ({ backgroundImage }) => (
  <div className="background-image">
    <Image image={backgroundImage || 'background.jpg'} />
  </div>
);

export default DashboardBackground;
