import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import ApiService from '../../../services/api-service';
import { showModal, hideModal } from '../../../actions/modal';
import Confirmation from '../../../components/Modals/Confirmation';
import { history } from '../../../App';
import { Column, Container, Row } from '../../Layout';
import { Button, Spinner } from '../../Common';
import Icon from '../Icon';
import { successNotification } from '../../../actions/notifications';
import { __ } from '../../../helpers/i18n';

import './TransactionVerifyMenu.scss';

interface Props {
  transactionId: string;
}

const TransactionVerifyMenu: FC<Props> = ({ transactionId }) => {
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);

  const transactionAction = async (actionType: 'reject' | 'accept', text: string) => {
    const endpointUrl = `transaction/${actionType}-transaction/${transactionId}`;
    const notificationText = __(`application.transaction-${actionType}`);
    setInProgress(true);
    await ApiService.callFetch(
      'PATCH',
      endpointUrl,
      () => {
        dispatch(successNotification(notificationText));
        history.push('/dashboard/transactions/list');
        setInProgress(false);
      },
      () => {
        setInProgress(false);
      },
      {
        rejectDescription: text,
      },
    );
  };

  const addCauseAndReject = () => {
    dispatch(
      showModal(
        <Confirmation
          confirmCallback={(value: 'accept' | 'reject', text: string) => {
            transactionAction('reject', text);
            dispatch(hideModal());
          }}
          text="Jaki jest powód odrzucenia tej transacji? Czy na pewno chcesz odrzucić transakcję?"
          showInput
        />,
      ),
    );
  };

  return (
    <section className="transaction-verify-actions">
      <Container>
        <Row>
          <Column className="reject-box box" autoWidth>
            <Button className="verify-button reject-button" click={() => addCauseAndReject()}>
              <div className="content">
                <Icon icon="transaction-reject" />
                Odrzuć
              </div>
            </Button>
          </Column>
          <Column className="accept-box box" role="button" autoWidth>
            <Button
              disabled={inProgress}
              className="verify-button accept-button"
              click={() => transactionAction('accept', '')}
            >
              {inProgress ? (
                <Spinner small />
              ) : (
                <div className="content">
                  <Icon icon="transaction-accept" />
                  Zaakceptuj
                </div>
              )}
            </Button>
          </Column>
        </Row>
      </Container>
    </section>
  );
};

export default TransactionVerifyMenu;
