import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UserRole } from 'topdekarze-types';
import { RootState } from '../../../reducers';
import useHeader from '../../../hooks/useHeader';
import { Main, Container } from '../../../components/Layout';

import { SendMailInfoForm, SendMessageInfoForm } from '../../../components/Form/SendInfoForms';
import SectionTitle from '../../../components/Common/SectionTitle';
import { __ } from '../../../helpers/i18n';
const MessagesManagement: FC = () => {
  useHeader(__('application.notifications'), '/dashboard');

  const user = useSelector((state: RootState) => state.user.details);

  // const recalculate = async () => {
  //   await ApiService.callFetch('GET', 'article/recalculate', () => {
  //     console.log('OK');
  //   });
  //   await ApiService.callFetch('GET', 'event/recalculate', () => {
  //     console.log('OK');
  //   });
  // };

  if (user?.role === UserRole.Admin) {
    return (
      <Main>
        <Container fluid>
          {/* Do not use, only if necessary */}
          {/* <Button text="Odśwież miniatury" click={recalculate} /> */}
          <SectionTitle>{__('application.sendToUsersMailNotification')}</SectionTitle>
          <SendMailInfoForm />
          <SectionTitle>{__('application.sendToUsersSmsNotification')}</SectionTitle>
          <SendMessageInfoForm />
        </Container>
      </Main>
    );
  }
  return null;
};

export default MessagesManagement;
