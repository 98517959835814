import React, { ReactNode } from 'react';
import './PageTitle.scss';
import clsx from 'clsx';

interface Props {
  className?: string;
  children: ReactNode;
}

const PageTitle = ({ className, children }: Props) => (
  <h1 className={clsx('page-title', { [`${className}`]: className })}>{children}</h1>
);

export default PageTitle;
