import React from 'react';
import { useSelector } from 'react-redux';
import ApiService from '../../../services/api-service';
import { RootState } from '../../../reducers';

import './UserImage.scss';

interface Props {
  image?: string;
  center?: boolean;
}

const UserImage = ({ image, center }: Props) => {
  const user = useSelector((state: RootState) => state.user.details);

  const initials = () => `${user?.firstName.charAt(0)} ${user?.lastName.charAt(0)}`;

  return (
    <div className="user-image">
      <img src={`${ApiService.url}user/avatar-mine`} alt={initials()} />
    </div>
  );
};

export default UserImage;
