import React from 'react';
import { TableResponse } from 'topdekarze-tables';
import uuid from 'uuid';

export interface ApiTableState extends TableResponse<any> {
  loading: boolean;
  uuid: string;
}

const initialState: ApiTableState = {
  countTotal: 0,
  items: [],
  uuid: uuid.v4(),
  loading: false,
};

interface FetchApiTableDataSuccess {
  type: 'FETCH_API_TABLE_DATA_SUCCESS';
  payload: TableResponse<any>;
}

interface RefreshApiTable {
  type: 'REFRESH_API_TABLE';
}

interface LoadingApiTable {
  type: 'LOADING_API_TABLE';
  payload: boolean;
}

type Action = FetchApiTableDataSuccess | RefreshApiTable | LoadingApiTable;

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'FETCH_API_TABLE_DATA_SUCCESS':
      return action.payload;
    case 'REFRESH_API_TABLE':
      return {
        ...state,
        uuid: uuid.v4(),
      };
    case 'LOADING_API_TABLE':
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
