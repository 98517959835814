import { combineReducers } from 'redux';
import appStatusReducer from './app-status-reducer';
import modalReducer from './modal-reducer';
import notificationsReducer from './notifications-reducer';
import langReducer from './lang-reducer';
import headerReducer from './header-reducer';
import userReducer from './user-reducer';
import tableReducer from './table-reducer';
import multiplePhoto from './multiple-photo-reducer';

const appReducer = combineReducers({
  appStatus: appStatusReducer,
  lang: langReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  header: headerReducer,
  user: userReducer,
  apiTable: tableReducer,
  multiplePhoto,
});

const rootReducer = (state: any, action: any) => appReducer(state, action);

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
