import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ButtonsContainer } from '../../../components/Layout';
import { Button } from '../../../components/Common';
import ModalContent from '../../../components/Common/Modal/ModalContent';
import ModalTitle from '../../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../../components/Common/Modal/ModalHeader';
import { modal } from '../../../actions';

import './Confirmation.scss';

interface Props {
  text: string;
  confirmCallback: (actionType: 'reject' | 'accept', value: any) => void;
  hideModal: () => void;
  children?: React.ReactNode;
  cancelCallback?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  showInput?: boolean;
  mailing?: boolean;
}

const Confirmation = ({
  text,
  confirmCallback,
  hideModal,
  showInput,
  children,
  cancelCallback,
  confirmLabel,
  cancelLabel,
  mailing,
}: Props) => {
  const [value, setValue] = useState<any>();

  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title="Potwierdzenie" />
      </ModalHeader>
      <p className="confirmation-paragraph">{text}</p>
      {showInput && (
        <input
          className="modal-input"
          type="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
          value={value}
        />
      )}
      <ButtonsContainer>
        <Button small click={cancelCallback || hideModal} text={cancelLabel || 'Nie'} secondary />
        <Button
          small
          click={mailing ? confirmCallback : () => confirmCallback('accept', value)}
          text={confirmLabel || 'Tak'}
          primary
        />
      </ButtonsContainer>
    </ModalContent>
  );
};

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ hideModal: modal.hideModal }, dispatch);

export default connect(null, mapDispatchToProps)(Confirmation);
