import React from 'react';
import { useDispatch } from 'react-redux';
import { FormFieldType } from 'itf_formbuilder';
import { getTranslatableError, ItfApiForm } from 'itf_formbuilder_react';
import { Link } from 'react-router-dom';
import { AuthLoginRequest, AuthLoginResult } from 'topdekarze-types';
import clsx from 'clsx';
import FormContainer from '../../Layout/FormContainer';
import { Spinner, Input, Button } from '../../Common';
import ApiService from '../../../services/api-service';
import { successNotification } from '../../../actions/notifications';
import { history } from '../../../App';
import { __ } from '../../../helpers/i18n';
import { formOnTranslateString } from '../../../utils/trans-form';

import './LoginForm.scss';
import { ButtonsContainer } from '../../Layout';
import { setUser } from '../../../actions/auth';

const LoginForm = () => {
  const dispatch = useDispatch();
  return (
    <ItfApiForm
      formId="loginForm"
      schemaMayBeDynamic
      schemaCacheEnabled={false}
      loadingInfo={<Spinner light overlay />}
      schemaFetcher={() => ApiService.loadForm('auth/form/login')}
      sendFetcher={(formId: string, values: AuthLoginRequest) => ApiService.sendForm('auth/login', values)}
      submitButton={
        <>
          <Link to="/restore-pass" className="forgot-password">
            {__('application.forgotPassword')}
          </Link>
          <ButtonsContainer max>
            <Button primary type="submit" text={__('application.login')} />
          </ButtonsContainer>
        </>
      }
      onTranslateString={formOnTranslateString}
      onSavedSuccessfully={(data: AuthLoginResult) => {
        dispatch(successNotification(`${__('application.loginCorrect')}`));
        dispatch(setUser(data.user));
      }}
    />
  );
};

export default LoginForm;
