import React, { FC } from 'react';
import { Main, Container } from '../../../../components/Layout';
import useHeader from '../../../../hooks/useHeader';
import AddSpecialActionForm from '../../../../components/Form/AddSpecialActionForm';

const AddSpecialAction: FC = () => {
  useHeader('Dodaj zadanie specjalne', '/dashboard/special-tasks');

  return (
    <Main>
      <Container>
        <AddSpecialActionForm />
      </Container>
    </Main>
  );
};

export default AddSpecialAction;
