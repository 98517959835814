import React, { FC } from 'react';
import { SectionSubtitle } from '../../components/Common';
import SectionTitle from '../../components/Common/SectionTitle';
import { Container, Main } from '../../components/Layout';
import useHeader from '../../hooks/useHeader';
import './CompetitionRules.scss';

const CompetitionRules: FC = () => {
  useHeader('Zasady konkursu', '/');

  return (
    <Main className="competition-rules">
      <Container>
        <img src="/assets/images/competition/1.jpg" alt="" />
        <SectionTitle highlight>NAGRODA GŁÓWNA</SectionTitle>
        <img src="/assets/images/competition/2.jpg" alt="" />
        <p>
          Uczestnicy programu „Top Dekarze – Nova Paka” biorąc udział w aktywnościach i rozwiązując zadania na
          platformie walczą o zdobycie jak największej ilości punktów. 30 dekarzy z największą liczbą punktów zdobędzie
          nagrody gwarantowane:
        </p>
        <img src="/assets/images/competition/3.jpg" alt="" />
        <SectionTitle highlight>ZABUDOWA PAKI</SectionTitle>
        <p>
          w ramach wygranego przez siebie budżetu, otrzyma w pełni personalizowaną i dopasowaną do posiadanego pojazdu
          zabudowę „PAKI” . Wraz z pomocą naszych specjalistów wybiorą najlepsze dla siebie elementy i moduły. Po
          przeniesieniu tego na projekt bierzemy się od razu za jego wykonanie. Wszystkie elementy będą realizowane z
          Moduł System - jednym z wiodących producentów samochodowych systemów regałowych na świecie i specjalistą w
          zakresie zabudowy samochodów dostawczych.
        </p>
        <img src="/assets/images/competition/4.jpg" alt="" />
        <SectionTitle highlight>ELEKTRONARZĘDZIA DEWALT</SectionTitle>
        <p>
          otrzyma wyjątkowe zestawy elektronarzędzi renomowanej marki DeWalt. Wygrane zestawy zróżnicowane są w
          zależności od finalnego miejsca w rankingu głównym. Zwycięzcy z miejsc od 11-20 wygrywają ZESTAW DeWALT COMBO,
          a w nim Szlifierka kątowa, Młotowiertarka, Wkrętarka udarowa, Zakrętarka udarowa wraz z niezbędnym
          wyposażeniem. Kolejnych 10 zwycięzców otrzyma Zestaw 2 (dwóch) wkrętarek udarowych!
        </p>
        <SectionTitle>Co musisz zrobić by otrzymać punkty?</SectionTitle>
        <ol className="ordered">
          <li>
            <p>
              Głównym zadaniem konkursu jest <strong>zgłaszanie realizacji dachów</strong> (o powierzchni min. 100 m
              <sup>2</sup>), wykonanych z wykorzystaniem produktów CREATON (dachówki ceramiczne i cementowe, membrana
              dachowa, taśmy dachowe). Za zgłoszenie dachu, za każdy z ww produktów otrzymujesz
              <strong> 500 punktów </strong>do rankingu głównego.
            </p>
            <p>
              <SectionSubtitle> W jaki sposób mogę je zgłosić?</SectionSubtitle>
            </p>
            <ol>
              <li>Zaloguj się do swojego profilu w programie TOP DEKARZ.</li>
              <li>
                Naciśnij przycisk <strong>„DODAJ REALIZACJĘ”</strong>
                <img className="image-small" src="/assets/images/competition/5.jpg" alt="" />
              </li>
              <li>
                Wgraj zdjęcie lub zrób zdjęcie dachu*. Upewnij się, że zdjęcie jest wyraźne i przedstawia zgłaszany
                produkt**.
              </li>
              <li>Uzupełnij wszystkie pola w formularzu zgłoszenia</li>
              <p> * dach nie może być mniejszy niż 100 m2</p>
              <p> ** zgłaszane produkty mogą być zakupione nie wcześniej niż 01.05.2020 r.</p>
            </ol>
            <div className="competition-rules-wrapper">
              <div className="question-mark">?</div>
              <div>
                <SectionSubtitle>Czy dachówki, taśmy i membrany mogę zgłosić w jednym zgłoszeniu? </SectionSubtitle>
                Nie. Każdy produkt powinien być zgłoszony osobno, w formularzu zgłoszenia zaznacz produkt, który
                zgłaszasz.
              </div>
            </div>
            <SectionSubtitle> Czy od razu po wysłaniu zgłoszenia otrzymuję punkty?</SectionSubtitle>
            Nie. Każde zgłoszenie wymaga weryfikacji i zaakceptowania przez Twojego Doradcę Handlowo-Technicznego z
            CREATON Polska. Jeśli chcesz sprawdzić kto jest Twoim Doradcą, sprawdź w swoim profilu.
          </li>
          <li className="no-marker">
            <div className="competition-rules-wrapper">
              <img className="image-small" src="/assets/images/competition/6.jpg" alt="" />
              <div>
                2. <strong>Quizy</strong> – cotygodniowe wyzwania, związane z rozwiązaniem testu wiedzy. Otrzymujesz 10
                punktów za każdą dobrą odpowiedź. Quizy mogą mieć od 4 do 8 pytań, do wielu z nich znajdziesz materiały
                pomocnicze w Bazie Wiedzy. Punkty naliczane są automatycznie.
              </div>
            </div>
          </li>
          <li>
            <strong>Aktywność w programie</strong> – zdobywasz punkty za dodatkowe działania na platformie konkursowej
            takie jak: Pierwsze logowanie – prawidłowe zalogowanie się do platformy konkursowej do 16.10.2020 r. – 350
            punktów na start
          </li>
          <li>
            <strong>Regularne logowanie</strong> – jeśli 3 razy w miesiącu odwiedzisz nasza platformę konkursową
            zdobędziesz 150 punktów za każdy miesiąc.
          </li>
          <li>
            <strong>Polecaj program TOP DEKARZ</strong> – z poziomu platformy konkursowej masz możliwość zaproszenia do
            udziału w konkursie innych dekarzy. Wystarczy, że wyślesz im zaproszenie. Jeśli zaproszony dekarz przyjmie
            Twoje zaproszenie i zarejestruje się do programu (rejestracja nowych użytkowników wymaga akceptacji po
            stronie Doradcy CREATON Polska), dostajesz dodatkowe 150 punktów.
          </li>
        </ol>
        <div className="competition-rules-wrapper">
          <div>
            <SectionTitle highlight>ZADANIA CZASOWE </SectionTitle>
            <p>
              W trakcie trwania programu, przewidujemy dodatkowe zadania dla Dekarzy. Dzięki nim, będziesz mógł otrzymać
              dodatkowe punkty do rankingu głównego oraz wygrywać atrakcyjne nagrody rzeczowe. Obserwuj ZADANIA CZASOWE,
              w opisie poszczególnych zadań znajduje się opis zadania, nagrody i punktacja. W trakcie programu
              przewidujemy jedno zadanie, rozstrzygane w dwóch turach.
            </p>
          </div>
          <img className="image-small" src="/assets/images/competition/7.jpg" alt="" />
        </div>
      </Container>
    </Main>
  );
};

export default CompetitionRules;
