import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { Row, FormContainer } from '../../Layout';
import { Button, Input, Spinner } from '../../Common';
import ApiService from '../../../services/api-service';
import { successNotification } from '../../../actions/notifications';

const NewPasswordForm = (): JSX.Element => {
  const { updateToken, userId } = useParams<{ updateToken: string; userId: string }>();
  const dispatch = useDispatch();
  return (
    <FormContainer>
      <ItfApiForm
        formId="resetPasswordForm"
        schemaMayBeDynamic={false}
        schemaCacheEnabled={false}
        loadingInfo={<Spinner overlay />}
        schemaFetcher={() => ApiService.loadForm('user/form/set-pass')}
        sendFetcher={(formId: string, values: FormData) =>
          ApiService.sendForm(
            'user/reset-pass',
            {
              ...values,
              updateToken,
              userId,
            },
            'PATCH',
          )
        }
        onSavedSuccessfully={() => {
          dispatch(successNotification('Hasło zostało ustawione.'));
        }}
        onRenderFullField={(field, errors, fieldHtmlId, currentValue, onChange, onFocus, onBlur) => {
          switch (field.type) {
            case 'text':
              return (
                <Input
                  type={field.options.type}
                  placeholder={field.placeholder}
                  label={field.title}
                  input={{
                    name: fieldHtmlId,
                    id: fieldHtmlId,
                    errors,
                    value: currentValue,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
                    onFocus,
                    onBlur,
                  }}
                />
              );
            default:
              return null;
          }
        }}
        submitButton={
          <Row buttonsContainer>
            <Button primary type="submit" text="Wyślij" />
          </Row>
        }
      />
    </FormContainer>
  );
};

export default NewPasswordForm;
