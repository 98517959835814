import { ItfApiForm } from 'itf_formbuilder_react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthLoginRequest, AuthLoginResult } from 'topdekarze-types';
import { setUser } from '../../../../actions/auth';
import { successNotification } from '../../../../actions/notifications';
import { history } from '../../../../App';
import { Spinner, Button } from '../../../../components/Common';
import { ButtonsContainer, Container, FormContainer, Main } from '../../../../components/Layout';
import { __ } from '../../../../helpers/i18n';
import useHeader from '../../../../hooks/useHeader';
import ApiService from '../../../../services/api-service';
import { formOnTranslateString } from '../../../../utils/trans-form';

const UsersPreview = () => {
  const { userId } = useParams();
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();
  return (
    <Main>
      <Container>
        <FormContainer>
          <ItfApiForm
            formId="previewUser"
            schemaMayBeDynamic
            schemaCacheEnabled={false}
            loadingInfo={<Spinner light overlay />}
            schemaFetcher={() => ApiService.loadForm(`user/form/get-user-data/${userId}`)}
            sendFetcher={(formId: string, values: any) => {
              setIsDisabled(true);
              ApiService.sendForm(`user/update-as-admin/${userId}`, values, 'PATCH');
            }}
            submitButton={
              <ButtonsContainer>
                <Button disabled={isDisabled} type="submit" secondary text={__('application.update')} />
              </ButtonsContainer>
            }
            onTranslateString={formOnTranslateString}
            onSavedSuccessfully={(data: AuthLoginResult) => {
              setIsDisabled(false);
              dispatch(successNotification(__('application.dateUpdated')));
              history.push('/dashboard/users');
            }}
            onErrorsWhileSaving={() => setIsDisabled(false)}
            onRenderFullField={(field) => {
              switch (field.id) {
                case 'companyFile':
                  if (field.value) {
                    return (
                      <ButtonsContainer marginB>
                        <Button
                          key={field.id}
                          download
                          blank
                          to={`${ApiService.url}user/get-company-file/${userId}`}
                          text="Pobierz logotyp"
                        />
                      </ButtonsContainer>
                    );
                  }
                  return <p key={field.id} />;
                default:
                  return null;
              }
            }}
          />
        </FormContainer>
      </Container>
    </Main>
  );
};

export default UsersPreview;
