import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pointsHistorySchema } from 'topdekarze-tables';
import { PointsHistoryTableRes, UserRole } from 'topdekarze-types';
import ApiService from '../../../../services/api-service';
import { InteractiveTableSchema } from '../../../../utils/table-schema-utils';
import { Main, Container, ButtonsContainer } from '../../../../components/Layout';
import ApiTable from '../../../../components/Common/ApiTable';
import { DashboardBackground, TableWrapper, Button, Spinner } from '../../../../components/Common';
import useHeader from '../../../../hooks/useHeader';
import EntrySection from '../../Start/EntrySection';
import { RootState } from '../../../../reducers';
import UserLevel from '../../Rating/UserLevel';
import SectionTitle from '../../../../components/Common/SectionTitle';

const MyHistory: FC = () => {
  useHeader('');
  const [history, setHistory] = useState<any>();
  const user = useSelector((state: RootState) => state.user.details);

  const getEvents = async () => {
    await ApiService.callFetch('GET', 'ranking/history', (data: PointsHistoryTableRes[]) => {
      setHistory(data);
    });
  };

  useEffect(() => {
    getEvents();
  }, []);

  const scheme = new InteractiveTableSchema(pointsHistorySchema).removeFields('id');

  if (!history) return <Spinner />;

  return (
    <Main className="start-view">
      <DashboardBackground />
      <EntrySection noStats />
      {user?.role === UserRole.User && <UserLevel />}
      <Container>
        <SectionTitle>Historia punktacji</SectionTitle>
      </Container>
      <TableWrapper>
        <ApiTable scheme={scheme} apiEndpointSubUrl="ranking/history" noSearch />
      </TableWrapper>
    </Main>
  );
};

export default MyHistory;
