import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { UserRole } from 'topdekarze-types';
import AddQuiz from './Add';
import RouteAdmin from '../../../components/Routes/RouteAdmin';
import ViewQuiz from './Quiz';
import ManageQuizzes from './Manage';
import ListQuizzes from './List';
import { RootState } from '../../../reducers';

const Quizzes: FC = () => {
  const user = useSelector((state: RootState) => state.user.details);
  return (
    <Switch>
      <Route
        exact
        path="/dashboard/quizzes"
        render={() =>
          [UserRole.Admin, UserRole.SuperAdmin].includes(user!.role) ? (
            <Redirect to="/dashboard/quizzes/manage" />
          ) : (
            <Redirect to="/dashboard/quizzes/list" />
          )
        }
      />
      <RouteAdmin path="/dashboard/quizzes/add" component={AddQuiz} />
      <Route path="/dashboard/quizzes/list" component={ListQuizzes} />
      <RouteAdmin path="/dashboard/quizzes/manage" component={ManageQuizzes} />
      <Route path="/dashboard/quizzes/view/:quizId/:quizStatus" component={ViewQuiz} />
    </Switch>
  );
};

export default Quizzes;
