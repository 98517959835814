import React from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from 'topdekarze-types';
import { Container, Main, Row } from '../../../../components/Layout';
import Card from '../../../../components/Common/Card';
import { Spinner } from '../../../../components/Common';
import PageTitle from '../../../../components/Common/PageTitle';
import useEventList from '../../../../hooks/events/useEventList';
import EntryText from '../../../../components/Common/EntryText';
import useHeader from '../../../../hooks/useHeader';
import { RootState } from '../../../../reducers';

const SpecialActionsList = (): JSX.Element => {
  useHeader('Zadania specjalne', '/dashboard');
  const { events, isLoading } = useEventList();
  const user = useSelector((state: RootState) => state.user.details);

  const timeToEnd = (date: Date): string => {
    const dayMiliseconds = 60 * 60 * 24 * 1000;
    const days = Math.floor((Date.parse(`${date}`) - Date.parse(`${new Date()}`)) / dayMiliseconds);
    if (days < 0) return '';

    switch (days) {
      case 0:
        return 'Kończy się dzisiaj!';
      case 1:
        return '1 dzień do końca';
      default:
        return `${days} dni do końca`;
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <Main>
      <Container>
        <EntryText>
          W tym miejscu znajdziesz informacje o akcjach specjalnych, za których realizację możesz zyskać ekstra punkty.
          Dodatkowe zadania to także szansa na zdobycie atrakcyjnych nagród rzeczowych! <br />
          {user?.role === UserRole.SuperUser && (
            <span>
              Tutaj też zobaczysz z jakimi zadaniami specjalnymi na platformie mierzą się Twoi Top Dekarze. Bądź na
              bieżąco!
            </span>
          )}
        </EntryText>
        <Row as="ul">
          {events.map((event, index) => {
            if (user?.role === event.participant) {
              return (
                <Card
                  key={event.id}
                  className="action"
                  title={event.title}
                  isFirst={false}
                  imgUrl={`event/event-image/${event.id}`}
                  to={`/dashboard/special-tasks/${event.id}`}
                  time={timeToEnd(event?.endDt)}
                  specialTask
                />
              );
            }
          })}
        </Row>
        {user?.role === UserRole.SuperUser && events.find((event) => event.participant !== user?.role) && (
          <Row as="ul">
            <PageTitle className="quiz-list-subtitle">Zadania dekarzy</PageTitle>
            {events.map((event, index) => {
              if (user?.role !== event.participant) {
                return (
                  <Card
                    key={event.id}
                    className="action"
                    title={event.title}
                    isFirst={false}
                    imgUrl={`event/event-image/${event.id}`}
                    to={`/dashboard/special-tasks/${event.id}`}
                    time={timeToEnd(event?.endDt)}
                    specialTask
                  />
                );
              }
            })}
          </Row>
        )}
      </Container>
    </Main>
  );
};

export default SpecialActionsList;
